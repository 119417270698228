import { useState, useEffect, useReducer, useContext, Dispatch, SetStateAction } from 'react';
import axios, { AxiosResponse, AxiosInstance } from 'axios';
// TODO: enable cache and throttle
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import NotificationContext from '../context/NotificationContext';
import defaultSunroof from '../data/defaultSunroof';
import {
  BillingCalculator,
  SimulationResult,
  SolarData,
} from '../../types/custom';
import stringToNum from '../utils/stringToNum';
import cityPostCodes from '../data/cityPostalCodes';

declare module 'axios' {
  interface AxiosRequestConfig {
    // if your cacheFlag was setting to 'useCache'
    useCache?: boolean;
  }
}

type DataFetchReducerState<R = any> = {
  isLoading: boolean;
  hasError: boolean;
  results?: R;
};

type DataFetchReducerAction = {
  type: 'FETCH_INIT' | 'POST_INIT' | 'FETCH_SUCCESS' | 'POST_SUCCESS' | 'FETCH_FAILURE' | 'POST_FAILURE';
  payload?: any;
};

type MemoMaxLength = number;

const createDataFetchReducer = <R>() => (state: DataFetchReducerState<R>, action: DataFetchReducerAction) => {
  switch (action.type) {
    case 'FETCH_INIT':
    case 'POST_INIT':
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case 'FETCH_SUCCESS':
    case 'POST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        results: action.payload,
      };
    case 'FETCH_FAILURE':
    case 'POST_FAILURE':
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      throw new Error();
  }
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: '/api/',
  headers: { 'Cache-Control': 'no-cache' },
  // TODO: enable cache and throttle
  // adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { enabledByDefault: false, cacheFlag: 'useCache' })),
});

function useGet<R = any>(url: string = '', otherHeaders?: { [key: string]: string }): { state: DataFetchReducerState<R>, setRequestUrl: Dispatch<SetStateAction<string>>, dispatch: Dispatch<DataFetchReducerAction> } {
  const [requestUrl, setRequestUrl] = useState<string>(url);
  const dataFetchReducer = createDataFetchReducer<R>();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    hasError: false,
  });

  useEffect(() => {
    let didCancel = false;

    const get = async () => {
      if (requestUrl) {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const response: AxiosResponse<R> = await axiosInstance.get(requestUrl, {
            headers: {
              ...otherHeaders,
            },
            useCache: true,
          });
          if (!didCancel && response?.status === 200) {
            dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
          } else {
            throw new Error('Status not 200');
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE' });
          }
        }
      }
    };

    get();

    return () => {
      didCancel = true;
    };
  }, [requestUrl]);

  return { state, setRequestUrl, dispatch };
}

function usePost<R = any>(url: string, otherHeaders?: { [key: string]: string }): [DataFetchReducerState<R>, Dispatch<SetStateAction<any>>] {
  const [postData, setPostData] = useState<any>();
  const { dispatch: notificationDispatch } = useContext(NotificationContext);
  const dataFetchReducer = createDataFetchReducer<R>();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    hasError: false,
  });

  useEffect(() => {
    let didCancel = false;
    let timeout: any;
    const MemoMaxLength = 100;

    const post = async () => {
      if (postData) {
        dispatch({ type: 'POST_INIT' });
        if (postData && postData.data.memo.length > MemoMaxLength) {
          dispatch({ type: 'POST_FAILURE' });
          notificationDispatch({ type: 'OPEN', payload: { color: 'error', message: '100文字以内で入力してください。' } });
        }

        try {
          const response: AxiosResponse<R> = await axiosInstance.post(url, postData, {
            headers: {
              ...otherHeaders,
            },
          });
          if (!didCancel && response?.status === 200) {
            dispatch({ type: 'POST_SUCCESS', payload: response.data });
            // timeout = setTimeout(() => notificationDispatch({ type: 'OPEN', payload: { color: 'success', message: 'this is a message' } }), 2000);
          } else {
            throw new Error('Status not 200');
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'POST_FAILURE' });
            timeout = setTimeout(() => notificationDispatch({ type: 'OPEN', payload: { color: 'error', message: 'エラーが発生しました。時間をおいて再度お試しください。' } }), 2000);
          }
        }
      }
    };

    post();

    return () => {
      didCancel = true;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [postData]);

  return [state, setPostData];
}

const usePostEnquiry = () => usePost('/enquiry/v2');

// シミュレーション結果の保存
const usePostSimulationResult = () => usePost('/simulation');

// 指定したドキュメントIDのシミュレーション結果を取得
const useGetSimulationResultByDocumentId = (): [DataFetchReducerState<SimulationResult>, (documentId: string) => void] => {
  const { state, setRequestUrl } = useGet();
  const makeRequest = (documentId: string) => setRequestUrl(`/simulation?documentid=${documentId}`);
  return [state, makeRequest];
};

// TODO: 追加したが使っていない。resultsのundefinedの処理をしないとエラーになる。
// 指定したユーザーIDのシミュレーション結果を取得
// const useGetSimulationResultsByUserId = (): [DataFetchReducerState<any[]>, (userId: string) => void] => {
//   const { state, setRequestUrl } = useGet();
//   const makeRequest = (userId: string) => setRequestUrl(`/simulation?userid=${userId}`);
//   return [state, makeRequest];
// };

const useGetBillingCalculator = (): [DataFetchReducerState<BillingCalculator>, (postcode: string) => void] => {
  const { state, setRequestUrl } = useGet<BillingCalculator>();
  const makeRequest = (postcode: string) => setRequestUrl(`/billingCalculator?postcode=${postcode}`);
  return [state, makeRequest];
};

// ソーラーデータを取得
const useGetSolarData = (): [DataFetchReducerState<SolarData>, ({ lat, lng, solarDataFileName }: { lat: number; lng: number; solarDataFileName: string }) => void] => {
  const { state, setRequestUrl, dispatch } = useGet();
  const defaultLat = 35.9817008972168;
  const defaultLng = 139.36009216308594;
  const makeRequest = ({ lat, lng, solarDataFileName }: { lat: number; lng: number; solarDataFileName: string }) => {
    if (solarDataFileName) {
      // ソーラーデータIDが指定されている場合、Cloud Storageからソーラーデータを取得
      setRequestUrl(`/solarData/v2?name=${solarDataFileName}`);
    } else if (lat.toFixed(5) === defaultLat.toFixed(5) && lng.toFixed(5) === defaultLng.toFixed(5)) {
      // 緯度・経度がデフォルト値の場合、ファイルからデフォルトのソーラーデータを取得
      dispatch({ type: 'FETCH_SUCCESS', payload: { ...defaultSunroof } });
    } else {
      // 上記以外の場合、Solar APIからソーラーデータを取得
      setRequestUrl(`/solarData/v2?lat=${lat.toFixed(6)}&lng=${lng.toFixed(6)}`);
    }
  };
  return [state, makeRequest];
};

const useGetServiceAreaByPrefecture = (): [DataFetchReducerState, (prefecture: string, postcode: string) => void] => {
  const { state, setRequestUrl } = useGet();
  const makeRequest = (prefecture: string, postcode: string) => setRequestUrl(`/serviceArea/${prefecture}/${postcode}`);
  return [state, makeRequest];
};

const useGetServiceAreaInstallers = <T>(): [DataFetchReducerState<T>, (areaId: string, city: string | null, postcode: string | number | boolean | null) => void] => {
  const { state, setRequestUrl } = useGet<T>();
  const makeRequest = (areaId: string, city: string | null, postcode: string | number | boolean | null) => {
    let requestUrl = `/serviceArea/${areaId}/installers`;
    if (city && cityPostCodes[city].includes(stringToNum(postcode))) {
      requestUrl += `/${city}`;
    }
    setRequestUrl(requestUrl);
  };
  return [state, makeRequest];
};

const usePostIntroduction = () => usePost('/introductions');

type UseGetCityAndPrefectureResponse = {
  city: string,
  prefecture: string,
};

const useGetCityAndPrefecture = (): [DataFetchReducerState<UseGetCityAndPrefectureResponse>, (postcode: string) => void] => {
  const { state, setRequestUrl } = useGet<UseGetCityAndPrefectureResponse>();

  const makeRequest = (postcode: string) => setRequestUrl(`/geocode/${postcode}`);
  return [state, makeRequest];
};

type UseGetGeocodeByAddressResponse = {
  isInsideJapan: boolean,
  geocodeData: {
    prefecture: string,
    lat: number,
    lng: number,
    city: string,
    postcode: string,
    postcodeFull: string,
    completeAddress: string,
  }
};

const useGetGeocodeByAddress = (): [DataFetchReducerState<UseGetGeocodeByAddressResponse>, (address: string) => void] => {
  const { state, setRequestUrl } = useGet<UseGetGeocodeByAddressResponse>();

  const makeRequest = (address: string) => setRequestUrl(`/geocode?address=${encodeURIComponent(address)}`);
  return [state, makeRequest];
};

export {
  usePostEnquiry,
  // シミュレーション結果の保存
  usePostSimulationResult,
  useGetSimulationResultByDocumentId,
  // useGetSimulationResultsByUserId,
  useGet,
  useGetBillingCalculator,
  useGetSolarData,
  useGetServiceAreaByPrefecture,
  useGetServiceAreaInstallers,
  usePostIntroduction,
  useGetCityAndPrefecture,
  useGetGeocodeByAddress,
};
