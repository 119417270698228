import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import getConfig from '../../config';

const useStyles = makeStyles(() => ({
  userProfilePicture: {
    borderRadius: '100%',
    maxWidth: 50,
    margin: '4px 0px 4px 0px',
    cursor: 'pointer',
    backgroundSize: 'cover',
    border: 0,
    width: 40,
    height: 40,
  },
  modalContent: {
    backgroundColor: '#fefefe',
    margin: '0',
    padding: '10px',
    border: '0px',
    '& a': {
      display: 'block',
      margin: '6px 6px 6px 0px',
      textDecoration: 'none',
      color: 'orange',
    },
  },
  show: {
    display: 'block',
  },
}));

const LoginButton = () => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const authConfig = getConfig();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const logoutWithRedirect = () => logout({
    clientId: authConfig.clientId,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="nav-container">
      {!isAuthenticated && (
        <button
          className={classes.userProfilePicture}
          onClick={() => loginWithRedirect()}
          aria-label="User Menu"
          type="button"
        >
          ▷
        </button>
      )}
      {isAuthenticated && (
        <div>
          {user && user.picture && (
            <button
              style={{ backgroundImage: `url(${user.picture})` }}
              className={classes.userProfilePicture}
              onClick={handleClick}
              aria-label="User Menu"
              type="button"
            />
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className={classes.modalContent}>
              <div>{user?.name}</div>
              <Link to="/resetpassword">パスワード変更</Link>
              <a href="#" onClick={logoutWithRedirect}>ログアウト</a>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default LoginButton;
