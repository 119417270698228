import { BillingCalculator, CashFlow, SystemCost, CashFlowSchedule, CashFlowParameters } from '../../types/custom';
import SIMULATION_PARAMETERS from '../context/SimulationParameters';
// import { monthlyKwhToYen } from './billing';

export const emptyCashFlow: CashFlow = {
  panelsCount: 0,
  energyUsagePerYear: 0,
  paybackPeriod: 0,
  totalFitRevenue: 0,
  totalElectricSavings: 0,
  totalSavingsBuy: 0,
  totalSavingsLoan: 0,
  cashFlowSchedule: [
    {
      year: 1,
      fitRevenue: 0,
      yearlyElectricSavings: 0,
      totalElectricSavingsToDate: 0,
      totalFitRevenueToDate: 0,
      totalFitAndElectricToDate: 0,
      yearlyEnergyAcKwh: 0,
      selfConsumption: 0,
      energySold: 0,
    },
  ],
};

// キャッシュフローのデフォルトパラメータ
export const defaultParameters: CashFlowParameters = {
  // TODO: 自家消費率？（画面の自家消費率とは意味合いが異なる？）
  selfConsumeRatio: 0.35,
  // インフレ率
  inflationRate: SIMULATION_PARAMETERS.INFLATION_RATE / 100,
  // 最大推奨パネル枚数（BtoBでは不要なためコメントアウト）
  // maxRecommendedPanelsCount: 40,
  // 最小推奨パネル枚数（BtoBでは不要なためコメントアウト）
  // minRecommendedPanelsCount: 8,
  // 発電収支の開始年
  startYear: SIMULATION_PARAMETERS.CASH_FLOW_START_YEAR,
  // 発電収支の終了年
  endYear: SIMULATION_PARAMETERS.CASH_FLOW_END_YEAR,
  // 卒FIT年
  finalFitYear: 10,
  // 出力低下率
  degradationRate: 1 - SIMULATION_PARAMETERS.DEGRADATION_RATE / 100,
  // 電気料金単価
  elecPricePerKWh: SIMULATION_PARAMETERS.ELEC_PRICE_PER_KWH_DEFAULT,
  // 電気使用量
  elecUsePerYear: SIMULATION_PARAMETERS.ELEC_USE_PER_YEAR_DEFAULT,
  // システム容量1kWあたりの設置費用
  panelCostPerKW: SIMULATION_PARAMETERS.PANEL_COST_PER_KW_DEFAULT,
  // 補助金
  grantAmount: SIMULATION_PARAMETERS.GRANT_AMOUNT_DEFAULT,
};

const calcPaybackPeriod = (cashFlowSchedule: CashFlowSchedule[], upfrontCost: number): number => {
  let year = 1;
  cashFlowSchedule.forEach((cashFlow) => {
    if (cashFlow.totalFitAndElectricToDate < upfrontCost) {
      year += 1;
    }
  });
  return year;
};

// 単年のキャッシュフローを計算する関数
const calcCashFlowForYear = (
  yearlyEnergyAcKwh: number,
  maxSelfConsumption: number,
  originalElectricCost: number,
  year: number,
  totalElectricSavingsToDate: number,
  totalFitRevenueToDate: number,
  yearlyConsumption: number,
  billingCalculator: BillingCalculator,
  parameters: CashFlowParameters = defaultParameters,
) => {
  const selfConsumption = 0; // 自家消費量を考慮しない
  const energySold = 0; // 売電量を常に0に変更
  const fitRevenue = 0; // BtoBでFitは使用しない
  // eslint-disable-next-line prefer-exponentiation-operator
  const energyCostInfrate = Math.pow(1 + parameters.inflationRate, year - 1);
  const yearlyElectricCost = Math.min(yearlyEnergyAcKwh, yearlyConsumption) * parameters.elecPricePerKWh;
  const yearlyElectricSavings = yearlyElectricCost * energyCostInfrate;
  const newTotalElectricSavingsToDate = totalElectricSavingsToDate + yearlyElectricSavings;
  const newTotalFitRevenueToDate = totalFitRevenueToDate + fitRevenue;
  return {
    year,
    fitRevenue,
    yearlyElectricSavings,
    totalElectricSavingsToDate: newTotalElectricSavingsToDate,
    totalFitRevenueToDate: newTotalFitRevenueToDate,
    totalFitAndElectricToDate: newTotalFitRevenueToDate + newTotalElectricSavingsToDate,
    yearlyEnergyAcKwh,
    selfConsumption,
    energySold,
  };
};

// 指定された期間のキャッシュフローのスケジュールを計算する関数
export const calcSpecificCase = (
  systemCost: SystemCost,
  yearlyConsumption: number,
  billingCalculator: BillingCalculator,
  monthlyBill: number,
  parameters: CashFlowParameters = defaultParameters,
): CashFlow => {
  const { startYear, endYear, degradationRate } = parameters;
  const cashFlowSchedule: CashFlowSchedule[] = [];
  let totalElectricSavingsToDate = 0;
  let totalFitRevenueToDate = 0;
  let newYearlyEnergyAcKwh = systemCost.yearlyEnergyAcKwh;

  // 単年度ごとに計算
  for (let year = startYear; year <= endYear; year++) {
    newYearlyEnergyAcKwh *= degradationRate;
    const newCashFlowSchedule = calcCashFlowForYear(
      newYearlyEnergyAcKwh,
      yearlyConsumption * parameters.selfConsumeRatio,
      monthlyBill,
      year,
      totalElectricSavingsToDate,
      totalFitRevenueToDate,
      yearlyConsumption,
      billingCalculator,
      parameters,
    );
    cashFlowSchedule.push(newCashFlowSchedule);

    totalElectricSavingsToDate += newCashFlowSchedule.yearlyElectricSavings;
    totalFitRevenueToDate += newCashFlowSchedule.fitRevenue; // BtoBの場合は常に0
  }

  // 最後の要素からトータルを取得
  const { totalFitAndElectricToDate } = cashFlowSchedule[cashFlowSchedule.length - 1];

  // 購入総節約額とローン総節約額を計算
  const totalSavingsBuy = totalFitAndElectricToDate - systemCost.upfrontCost - systemCost.maintenanceCost;
  const totalSavingsLoan = totalSavingsBuy - (systemCost.loan ? systemCost.loan.totalInterest : 0);

  // 返済期間を計算
  const paybackPeriod = calcPaybackPeriod(cashFlowSchedule, systemCost.upfrontCost + systemCost.maintenanceCost - parameters.grantAmount);

  // 最終的なキャッシュフローを返す
  const cashFlow: CashFlow = {
    panelsCount: systemCost.panelsCount,
    energyUsagePerYear: yearlyConsumption,
    paybackPeriod,
    totalFitRevenue: totalFitRevenueToDate,
    totalElectricSavings: totalElectricSavingsToDate,
    totalSavingsBuy,
    totalSavingsLoan,
    cashFlowSchedule,
  };

  return cashFlow;
};
