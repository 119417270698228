const buildingData = {
  name: 'buildings/ChIJ_dYdgXMqGWAR9ns4NxqNFcc',
  center: { latitude: 35.9817008972168, longitude: 139.36009216308594 },
  imageryDate: { year: 2015, month: 4, day: 18 },
  regionCode: 'JP',
  solarPotential: {
    maxArrayPanelsCount: 22,
    maxArrayAreaMeters2: 36.009598,
    maxSunshineHoursPerYear: 1391.0012,
    carbonOffsetFactorKgPerMwh: 550.9993,
    wholeRoofStats: {
      areaMeters2: 87.26481,
      sunshineQuantiles: [
        473.7135, 1000.3381, 1044.152, 1070.7302, 1118.3213, 1203.8778,
        1299.3391, 1345.19, 1360.8229, 1372.0842, 1399.5507,
      ],
    },
    roofSegmentStats: [
      {
        pitchDegrees: 25.707067,
        azimuthDegrees: 217.13649,
        stats: {
          areaMeters2: 19.899591,
          sunshineQuantiles: [
            848.4228, 1200.4176, 1320.6661, 1344.2919, 1356.1431, 1359.1312,
            1361.1055, 1361.8855, 1362.412, 1374.2074, 1399.5507,
          ],
        },
        center: { latitude: 35.981685638427734, longitude: 139.3600616455078 },
        boundingBox: {
          sw: { latitude: 35.98165512084961, longitude: 139.3600158691406 },
          ne: { latitude: 35.98170852661133, longitude: 139.36009216308594 },
        },
      },
      {
        pitchDegrees: 29.429216,
        azimuthDegrees: 37.870323,
        stats: {
          areaMeters2: 11.814504,
          sunshineQuantiles: [
            803.95825, 999.97205, 999.9768, 1000.7323, 1005.662, 1016.8993,
            1036.1682, 1060.2814, 1088.0757, 1118.3469, 1254.3066,
          ],
        },
        center: { latitude: 35.981719970703125, longitude: 139.36012268066406 },
        boundingBox: {
          sw: { latitude: 35.9817008972168, longitude: 139.36007690429688 },
          ne: { latitude: 35.981746673583984, longitude: 139.3601531982422 },
        },
      },
      {
        pitchDegrees: 24.471252,
        azimuthDegrees: 130.77737,
        stats: {
          areaMeters2: 11.107824,
          sunshineQuantiles: [
            910.3368, 1253.4712, 1340.9552, 1342.6605, 1343.6765, 1344.0908,
            1345.6282, 1351.108, 1362.819, 1372.8899, 1390.8077,
          ],
        },
        center: { latitude: 35.981685638427734, longitude: 139.36009216308594 },
        boundingBox: {
          sw: { latitude: 35.981658935546875, longitude: 139.36007690429688 },
          ne: { latitude: 35.981712341308594, longitude: 139.360107421875 },
        },
      },
      {
        pitchDegrees: 15.766953,
        azimuthDegrees: 340.80963,
        stats: {
          areaMeters2: 10.016887,
          sunshineQuantiles: [
            505.83868, 1081.4167, 1104.0957, 1107.0713, 1114.6307, 1118.7495,
            1121.4099, 1122.527, 1124.866, 1166.3995, 1277.9836,
          ],
        },
        center: { latitude: 35.98172378540039, longitude: 139.36007690429688 },
        boundingBox: {
          sw: { latitude: 35.98169708251953, longitude: 139.3600616455078 },
          ne: { latitude: 35.981746673583984, longitude: 139.360107421875 },
        },
      },
      {
        pitchDegrees: 26.998371,
        azimuthDegrees: 308.39053,
        stats: {
          areaMeters2: 9.651866,
          sunshineQuantiles: [
            473.7135, 703.7648, 1020.439, 1026.47, 1045.1975, 1062.3483,
            1066.0403, 1070.5775, 1079.4169, 1132.1989, 1256.0763,
          ],
        },
        center: { latitude: 35.981719970703125, longitude: 139.36004638671875 },
        boundingBox: {
          sw: { latitude: 35.98170471191406, longitude: 139.3600158691406 },
          ne: { latitude: 35.98174285888672, longitude: 139.3600616455078 },
        },
      },
      {
        pitchDegrees: 29.451015,
        azimuthDegrees: 122.52201,
        stats: {
          areaMeters2: 7.786155,
          sunshineQuantiles: [
            772.3972, 986.88654, 1016.9899, 1186.4464, 1205.5377, 1221.9,
            1233.0914, 1238.8278, 1246.576, 1254.2255, 1313.2103,
          ],
        },
        center: { latitude: 35.9816780090332, longitude: 139.3601531982422 },
        boundingBox: {
          sw: { latitude: 35.981651306152344, longitude: 139.36012268066406 },
          ne: { latitude: 35.9817008972168, longitude: 139.36016845703125 },
        },
      },
      {
        pitchDegrees: 19.501099,
        azimuthDegrees: 176.82033,
        stats: {
          areaMeters2: 6.3651357,
          sunshineQuantiles: [
            1067.612, 1216.744, 1347.5753, 1382.751, 1386.6967, 1389.007,
            1389.487, 1389.9557, 1390.8273, 1391.5996, 1392.1589,
          ],
        },
        center: { latitude: 35.981689453125, longitude: 139.36012268066406 },
        boundingBox: {
          sw: { latitude: 35.98167419433594, longitude: 139.360107421875 },
          ne: { latitude: 35.981712341308594, longitude: 139.36012268066406 },
        },
      },
      {
        pitchDegrees: 23.002794,
        azimuthDegrees: 28.486092,
        stats: {
          areaMeters2: 5.616599,
          sunshineQuantiles: [
            501.43777, 1043.4243, 1044.341, 1045.81, 1053.4138, 1057.246,
            1058.2611, 1059.2058, 1069.5548, 1112.9249, 1276.9294,
          ],
        },
        center: { latitude: 35.981719970703125, longitude: 139.3600616455078 },
        boundingBox: {
          sw: { latitude: 35.98169708251953, longitude: 139.3600616455078 },
          ne: { latitude: 35.98174285888672, longitude: 139.36007690429688 },
        },
      },
      {
        pitchDegrees: 26.506685,
        azimuthDegrees: 136.08617,
        stats: {
          areaMeters2: 5.0062447,
          sunshineQuantiles: [
            919.23724, 1023.5953, 1042.1311, 1181.4452, 1271.482, 1325.4852,
            1354.86, 1354.8624, 1355.0197, 1366.5465, 1377.5588,
          ],
        },
        center: { latitude: 35.981693267822266, longitude: 139.3601379394531 },
        boundingBox: {
          sw: { latitude: 35.98167419433594, longitude: 139.36012268066406 },
          ne: { latitude: 35.98170471191406, longitude: 139.3601531982422 },
        },
      },
    ],
    solarPanelConfigs: [
      {
        panelsCount: 4,
        yearlyEnergyDcKwh: 1374.9108,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 2,
            yearlyEnergyDcKwh: 682.3052,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 5,
        yearlyEnergyDcKwh: 1715.2316,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 3,
            yearlyEnergyDcKwh: 1022.626,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 6,
        yearlyEnergyDcKwh: 2055.4685,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 4,
            yearlyEnergyDcKwh: 1362.863,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 7,
        yearlyEnergyDcKwh: 2394.4963,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 5,
            yearlyEnergyDcKwh: 1701.8906,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 8,
        yearlyEnergyDcKwh: 2731.7158,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 6,
            yearlyEnergyDcKwh: 2039.1102,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 9,
        yearlyEnergyDcKwh: 3068.4941,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 6,
            yearlyEnergyDcKwh: 2039.1102,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 1,
            yearlyEnergyDcKwh: 336.7784,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 10,
        yearlyEnergyDcKwh: 3405.073,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 6,
            yearlyEnergyDcKwh: 2039.1102,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 11,
        yearlyEnergyDcKwh: 3729.3633,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 12,
        yearlyEnergyDcKwh: 4033.0876,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 1,
            yearlyEnergyDcKwh: 303.7242,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 13,
        yearlyEnergyDcKwh: 4336.355,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 14,
        yearlyEnergyDcKwh: 4618.4683,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 1,
            yearlyEnergyDcKwh: 282.11298,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 15,
        yearlyEnergyDcKwh: 4897.557,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 2,
            yearlyEnergyDcKwh: 561.20166,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 16,
        yearlyEnergyDcKwh: 5175.4404,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 17,
        yearlyEnergyDcKwh: 5441.8965,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 26.998371,
            azimuthDegrees: 308.39053,
            panelsCount: 1,
            yearlyEnergyDcKwh: 266.45587,
            segmentIndex: 4,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 18,
        yearlyEnergyDcKwh: 5707.4272,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 26.998371,
            azimuthDegrees: 308.39053,
            panelsCount: 2,
            yearlyEnergyDcKwh: 531.9866,
            segmentIndex: 4,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 19,
        yearlyEnergyDcKwh: 5965.4434,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 29.429216,
            azimuthDegrees: 37.870323,
            panelsCount: 1,
            yearlyEnergyDcKwh: 258.01602,
            segmentIndex: 1,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 26.998371,
            azimuthDegrees: 308.39053,
            panelsCount: 2,
            yearlyEnergyDcKwh: 531.9866,
            segmentIndex: 4,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 20,
        yearlyEnergyDcKwh: 6220.0967,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 29.429216,
            azimuthDegrees: 37.870323,
            panelsCount: 2,
            yearlyEnergyDcKwh: 512.66943,
            segmentIndex: 1,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 26.998371,
            azimuthDegrees: 308.39053,
            panelsCount: 2,
            yearlyEnergyDcKwh: 531.9866,
            segmentIndex: 4,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 21,
        yearlyEnergyDcKwh: 6476.376,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 29.429216,
            azimuthDegrees: 37.870323,
            panelsCount: 3,
            yearlyEnergyDcKwh: 768.9489,
            segmentIndex: 1,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 26.998371,
            azimuthDegrees: 308.39053,
            panelsCount: 2,
            yearlyEnergyDcKwh: 531.9866,
            segmentIndex: 4,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
      {
        panelsCount: 22,
        yearlyEnergyDcKwh: 6732.276,
        roofSegmentSummaries: [
          {
            pitchDegrees: 25.707067,
            azimuthDegrees: 217.13649,
            panelsCount: 7,
            yearlyEnergyDcKwh: 2363.4006,
          },
          {
            pitchDegrees: 29.429216,
            azimuthDegrees: 37.870323,
            panelsCount: 4,
            yearlyEnergyDcKwh: 1024.8484,
            segmentIndex: 1,
          },
          {
            pitchDegrees: 24.471252,
            azimuthDegrees: 130.77737,
            panelsCount: 2,
            yearlyEnergyDcKwh: 673.3571,
            segmentIndex: 2,
          },
          {
            pitchDegrees: 15.766953,
            azimuthDegrees: 340.80963,
            panelsCount: 3,
            yearlyEnergyDcKwh: 839.0855,
            segmentIndex: 3,
          },
          {
            pitchDegrees: 26.998371,
            azimuthDegrees: 308.39053,
            panelsCount: 2,
            yearlyEnergyDcKwh: 531.9866,
            segmentIndex: 4,
          },
          {
            pitchDegrees: 29.451015,
            azimuthDegrees: 122.52201,
            panelsCount: 2,
            yearlyEnergyDcKwh: 606.9917,
            segmentIndex: 5,
          },
          {
            pitchDegrees: 19.501099,
            azimuthDegrees: 176.82033,
            panelsCount: 2,
            yearlyEnergyDcKwh: 692.6056,
            segmentIndex: 6,
          },
        ],
      },
    ],
  },
  boundingBox: {
    sw: { latitude: 35.981651306152344, longitude: 139.3600158691406 },
    ne: { latitude: 35.981746673583984, longitude: 139.36016845703125 },
  },
};

export default buildingData;
