import { SolarPanelConfig, GenerationParameters } from '../../types/custom';
import SIMULATION_PARAMETERS from '../context/SimulationParameters';

export const defaultParameters = {
  // デフォルト日照時間（ソーラーデータが取得できなかった場合に使用）
  defaultSunshineHours: 1250,
  // パネル発電効率
  baseEfficiency: SIMULATION_PARAMETERS.BASE_EFFICIENCY / 100,
  // パネル発電効率
  japanEfficiency: SIMULATION_PARAMETERS.JAPAN_EFFICIENCY / 100,
  // パワコン効率
  inverterEfficiency: SIMULATION_PARAMETERS.INVERTER_EFFICIENCY / 100,
  // パネル1枚あたりの発電量
  averageGenerationPerPanel: SIMULATION_PARAMETERS.POWER_GENERATION_PER_PANEL,
  // スケールポテンシャル
  scalePotential: SIMULATION_PARAMETERS.SCALE_POTENTIAL / 100,
  // パネルサイズ
  panelSizeMeters2: SIMULATION_PARAMETERS.PANEL_SIZE_SIDE1 * SIMULATION_PARAMETERS.PANEL_SIZE_SIDE2,
};

// 発電効率の調整
export const adjustEfficiency = (solarConfig: SolarPanelConfig, parameters: GenerationParameters = defaultParameters) => {
  const { baseEfficiency, japanEfficiency, inverterEfficiency } = parameters;

  const scale = parameters?.scalePotential || 1;
  let base = solarConfig.yearlyEnergyDcKwh * scale;
  solarConfig.yearlyEnergyDcKwhAdjusted = base * (japanEfficiency / baseEfficiency);
  solarConfig.yearlyEnergyAcKwhAdjusted = solarConfig.yearlyEnergyDcKwhAdjusted * inverterEfficiency;

  solarConfig.roofSegmentSummaries?.forEach((rs) => {
    base = rs.yearlyEnergyDcKwh * scale;
    rs.yearlyEnergyDcKwhAdjusted = base * (japanEfficiency / baseEfficiency);
    rs.yearlyEnergyAcKwhAdjusted = rs.yearlyEnergyDcKwhAdjusted * inverterEfficiency;
  });
  return solarConfig;
};

// ソーラーデータを取得できなかった場合のSolarPanelConfigsの取得
export const mockSystemData = (parameters: GenerationParameters = defaultParameters): SolarPanelConfig[] => {
  const { inverterEfficiency, averageGenerationPerPanel, defaultSunshineHours } = parameters;
  const solarConfigs: SolarPanelConfig[] = [];
  for (let panelsCount = 8; panelsCount <= 40; panelsCount++) {
    const yearlyEnergyDcKwh: number = panelsCount * averageGenerationPerPanel * defaultSunshineHours;
    solarConfigs.push({
      panelsCount,
      yearlyEnergyDcKwh,
      yearlyEnergyDcKwhAdjusted: yearlyEnergyDcKwh,
      yearlyEnergyAcKwhAdjusted: yearlyEnergyDcKwh * inverterEfficiency,
      roofSegmentSummaries: [
        {
          pitchDegrees: 0,
          azimuthDegrees: 0,
          panelsCount,
          yearlyEnergyDcKwh,
          yearlyEnergyDcKwhAdjusted: yearlyEnergyDcKwh,
          yearlyEnergyAcKwhAdjusted: yearlyEnergyDcKwh * inverterEfficiency,
        },
      ],
    });
  }
  return solarConfigs;
};
