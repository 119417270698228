// Configure the audience here. By default, it will take whatever is in the config
// (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
// is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
// don't have an API).
// If this resolves to `null`, the API page changes to show some helpful info about what to do
// with the audience.
import authConfig from './auth_config';

interface AuthConfig {
  domain: string;
  clientId: string;
  audience?: string;
}

const config = authConfig as AuthConfig;

interface Config {
  domain: string;
  clientId: string;
  audience?: string;
}

export default function getConfig(): Config {
  const audience = config.audience && config.audience !== 'YOUR_API_IDENTIFIER' ? config.audience : null;

  return {
    domain: config.domain,
    clientId: config.clientId,
    ...(audience ? { audience } : {}),
  };
}
