import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';
import iconArrow from './icon_arrow_white.png';
import MemoizeHelper from '../MemoizeHelper';

const buttonArrowStyles = {
  display: 'block',
  content: '""',
  background: `url(${iconArrow}) left top/100% no-repeat`,
  position: 'absolute',
  pointerEvents: 'none',
  [mq.smOnly]: {
    right: 12,
    top: 18,
    width: 8,
    height: 12,
  },
  [mq.mdUp]: {
    right: 20,
    top: 20,
    width: 9,
    height: 14,
  },
};

const useStyles = makeStyles(() => ({
  Button: {
    fontWeight: 700,
    textDecoration: 'none',
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.orange,
    color: colors.white,
    lineHeight: 1,
    position: 'relative',
    borderRadius: 50,
    boxShadow: '0 3px 0 rgba(190,127,0,1)',
    transition: 'all .75s ease',
    padding: '12px 22px',
    cursor: 'pointer',
    border: 'none',
    fontSize: 16,
    '&:hover': {
      backgroundColor: colors.orangeHover,
      boxShadow: '0 3px 0 rgba(190,152,32,1)',
    },
    '&:disabled': {
      cursor: 'unset',
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
  'Button--link': {
    [mq.smOnly]: {
      width: '100%',
      maxWidth: 310,
      padding: '16px 10px',
    },
    [mq.mdUp]: {
      width: '100%',
      maxWidth: 420,
      padding: '18px 20px',
      fontSize: 16,
    },
    '&::after': { ...buttonArrowStyles },
  },
  'Button--submit': {
    [mq.smOnly]: {
      minWidth: 154,
      padding: '16px 10px',
      fontSize: 13,
    },
    [mq.mdUp]: {
      minWidth: 240,
      padding: '18px 20px',
      fontSize: 16,
    },
    '&::after': { ...buttonArrowStyles },
  },
  'Button--banner': {
    boxShadow: 'none',
    margin: '24px 8px 0',
    width: 'calc(100% - 16px)',
    whiteSpace: 'nowrap',
    [mq.smOnly]: {
      padding: '16px 8px',
      fontSize: 12,
    },
    [mq.mdUp]: {
      padding: '20px 28px',
      fontSize: 20,
      height: 62,
    },
  },
  'Button--white': {
    backgroundColor: colors.white,
    color: colors.orange,
    border: `1px solid ${colors.orange}`,
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.orangeHover,
    },
  },
  'Button--blue': {
    width: '100%',
    [mq.smOnly]: {
      maxWidth: 300,
      padding: '16px 20px',
      fontWeight: 'bold',
      fontSize: 16,
    },
    [mq.mdUp]: {
      maxWidth: '100%',
      padding: '30px 20px',
      fontWeight: 700,
      fontSize: 30,
      '&::after': {
        width: 16,
        height: 24,
        right: 50,
        top: 34,
      },
    },
    backgroundColor: '#00a8b1',
    color: colors.white,
    border: '1px solid #00a8b1',
    boxShadow: '1px 4px 0 #028289',
    '&:hover': {
      backgroundColor: '#00a8b1',
      color: colors.white,
      boxShadow: '1px 4px 0 #028289',
    },
  },
  'Button--giant': {
    width: '100%',
    [mq.smOnly]: {
      maxWidth: 300,
      padding: '16px 20px',
      fontWeight: 'bold',
      fontSize: 16,
    },
    [mq.mdUp]: {
      maxWidth: '100%',
      padding: '30px 20px',
      fontWeight: 700,
      fontSize: 30,
      '&::after': {
        width: 16,
        height: 24,
        right: 50,
        top: 34,
      },
    },
  },
  'Button--bar': {
    [mq.smOnly]: {
      fontSize: 14,
    },
    '& + $Button--bar': {
      [mq.smOnly]: {
        marginLeft: 8,
      },
      [mq.mdUp]: {
        marginLeft: 16,
      },
    },
  },
  'Button--back': {
    background: '#d4d4d4',
    color: colors.white,
    boxShadow: '0px 3px 0px #c3c3c3',
    [mq.smOnly]: {
      minWidth: 154,
      padding: '16px 10px',
      fontSize: 13,
    },
    [mq.mdUp]: {
      minWidth: 240,
      padding: '18px 20px',
      fontSize: 16,
    },
    '&:hover': {
      backgroundColor: '#d4d4d4',
      boxShadow: '0px 3px 0px #c3c3c3',
    },
    '&::before': {
      ...buttonArrowStyles,
      transform: 'rotate(180deg)',
      [mq.smOnly]: {
        left: 12,
        top: 18,
        width: 8,
        height: 12,
      },
      [mq.mdUp]: {
        left: 20,
        top: 20,
        width: 9,
        height: 14,
      },
    },
  },
}));

type ButtonProps = {
  to?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'banner' | 'link' | 'bar' | 'back';
  disabled?: boolean;
  variant?: 'white' | 'giant' | 'blue';
  className?: string;
  dataTestid?: string;
  dataAttr?: string;
  target?: '_blank';
};

const Button: React.FC<ButtonProps> = ({ to, children, onClick, type, disabled, variant, className, dataAttr, dataTestid, target }) => {
  const classes: { [key: string]: any } = useStyles();
  const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.+~#?&//=]*)?/gi;
  const httpRoute = new RegExp(expression);
  if (to) {
    if (to.match(httpRoute)) {
      return (
        <a
          className={clsx(classes.Button, className && className, classes[`Button--${type}`], variant && classes[`Button--${variant}`])}
          // to={(location) => ({ ...location, pathname: to })}
          href={to}
          onClick={onClick}
          data-attr={dataAttr}
          data-testid={dataTestid}
          target={target}
          rel="noopener noreferrer"
        >{children}
        </a>
      );
    }
    return (
      <Link
        className={clsx(classes.Button, className && className, classes[`Button--${type}`], variant && classes[`Button--${variant}`])}
        // to={(location) => ({ ...location, pathname: to })}
        to={{ pathname: to }}
        onClick={onClick}
        data-attr={dataAttr}
        data-testid={dataTestid}
        target={target}
      >{children}
      </Link>
    );
  }

  if (type === 'submit') {
    return (
      <button
        className={clsx(classes.Button, className && className, classes['Button--submit'])}
        type="submit"
        onClick={onClick}
        disabled={disabled}
        data-attr={dataAttr}
        data-testid={dataTestid}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      className={clsx(classes.Button, className && className, classes[`Button--${type}`], variant && classes[`Button--${variant}`])}
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-attr={dataAttr}
      data-testid={dataTestid}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  to: undefined,
  onClick: undefined,
  type: 'button',
  disabled: undefined,
  variant: undefined,
  className: undefined,
  dataTestid: undefined,
  dataAttr: undefined,
  target: undefined,
};

export default MemoizeHelper<ButtonProps>(Button);
