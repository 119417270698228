import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import TagManager from './lib/utils/ScriptLoader';
import getConfig from './config';
import App from './App';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-K4W4HC2',
  };
  TagManager.initialize(tagManagerArgs);
}

function Main() {
  const unmounted = useRef(false);
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=ja`;
      if (!unmounted.current) {
        document.head.appendChild(script);
      }
    }
    return () => {
      unmounted.current = true;
    };
  }, []);
  const authConfig = getConfig(); // getConfig関数を使用して設定を取得

  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  );
}

Loadable.preloadReady().then(() => {
  ReactDOM.hydrate(<Main />, document.getElementById('root'));
});
