import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  NumberDisplay: {
    fontFamily: "'aileronregular','ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体',sans-serif",
    letterSpacing: '-1px',
    lineHeight: 1,
    fontSize: '2.875em',
    margin: '0',
    color: `${colors.textDarkGray}`,
    fontWeight: 'normal',
    [mq.mdOnly]: {
      fontSize: '2.5em',
    },
    [mq.smOnly]: {
      fontSize: '2.3em',
    },
  },
  endSymbol: {
    fontSize: '.3em',
    marginLeft: '.4em',
    fontWeight: 'bold',
    color: `${colors.textGray}`,
    display: 'inline-block',
    width: '3.5em',
  },
}));

type NumberDisplayProps = {
  className?: string;
  endSymbol?: string;
  number?: any;
};

const NumberDisplay: React.FC<NumberDisplayProps> = ({ children, number, className, endSymbol }) => {
  const classes = useStyles();

  const [language, setLanguage] = useState('ja');

  useEffect(() => {
    setLanguage(navigator.language);
  }, []);

  return (
    <>
      <span className={clsx(classes.NumberDisplay, className)}>{number.toLocaleString(language, { minimumFractionDigits: 0 })}
        <span className={classes.endSymbol}>{endSymbol}</span>
      </span>
      {children}
    </>
  );
};

NumberDisplay.defaultProps = {
  className: undefined,
  endSymbol: undefined,
  number: undefined,
};

export default NumberDisplay;
