import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import clsx from 'clsx';
import { colors, mediaQueries as mq } from '../../../lib/styles';

const useStyles = makeStyles(() => ({
  Footer: {
    borderTop: `1px solid ${colors.borderColor}`,
    marginTop: 'auto',
    [mq.smOnly]: {
      padding: '40px 0 20px 0',
    },
    [mq.mdUp]: {
      padding: '24px 0 64px',
    },
  },
  FooterNav__link: {
    marginBottom: '24px',
    fontFamily: "'Yu Gothic','ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体',sans-serif",
    color: 'inherit',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1,
    marginTop: 16,
    display: 'block',
    [mq.smOnly]: {
      textAlign: 'center',
      fontSize: 14,
      marginLeft: 8,
    },
    [mq.mdUp]: {
      '& + $FooterNav__link': {
        borderLeft: 'none',
      },
    },
    [mq.xsOnly]: {
      marginLeft: 0,
    },
  },
  ButtonLink: {
    background: 'none!important',
    border: 'none',
    cursor: 'pointer',
    padding: '0!important',
  },
  underline: {
    textDecoration: 'underline',
  },
  Footer__logo: {
    marginTop: 24,
    [mq.smOnly]: {
      width: 96.5,
      height: 25,
    },
    [mq.mdUp]: {
      width: 140,
      height: 36,
    },
  },
  Footer__copyrightSymbol: {
    fontSize: '1rem',
    textAlign: 'center',
  },
  basicWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  OdawaraFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    [mq.smOnly]: {
      fontSize: 10,
    },
  },
  OdawaraFooter__link: {
    color: colors.textColor,
    fontWeight: 'normal',
  },

  footerContent: {
    maxWidth: '1360px',
    padding: '0 40px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mq.mdDown]: {
      padding: '0 30px',
    },
    [mq.smOnly]: {
      flexDirection: 'column-reverse',
      padding: '0 20px',
    },
  },
  sharpStyle: {
    color: '#f5f8fa',
  },
  sharpBackground: {
    backgroundColor: '#59574c',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    [mq.smOnly]: {
      alignItems: 'center',
    },
  },
  copyrightText: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    fontSize: '.75em',
    fontWeight: 500,
    color: 'inherit',
    lineHeight: 1.4,
    marginTop: '20px',
    [mq.smOnly]: {
      textAlign: 'center',
      fontSize: '.8em',
    },
  },
  footerMenu: {
    display: 'flex',
    flexDirection: 'row',
    [mq.smOnly]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#424242',
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);',
    padding: 24,
  },
  pos: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

// function getModalStyle() {
//   const top = 50;
//   const left = 50;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

const PrivacyModal: React.FC = ({ children }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const body = (
  // );

  return (
    <div>
      <button
        type="button"
        className={`${classes.FooterNav__link} ${classes.ButtonLink}`}
        onClick={() => handleOpen()}
      >
        {children}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={clsx(
          classes.paper,
          open && classes.pos,
        )}
        >
          <p className={`${classes.FooterNav__link} ${classes.sharpStyle}`}>
            本サイトでは個人情報を取得していません。プライバシーポリシーをご覧になりたい方は<a href="https://www.tepco.co.jp/privacypolicy/" target="_blank" rel="noopener noreferrer">こちら</a>からご確認ください。
          </p>
          <button className={`${classes.ButtonLink} ${classes.sharpStyle} ${classes.underline}`} type="button" onClick={() => handleClose()}>Close</button>
        </div>
      </Modal>
    </div>
  );
};

export default PrivacyModal;
