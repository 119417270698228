import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import { HelmetProvider, FilledContext } from 'react-helmet-async';
import { useAuth0 } from '@auth0/auth0-react';
import { PageWrap, Main, Notification } from './components';
import { NotificationContextProvider } from './lib/context/NotificationContext';
import { SearchAddressContextProvider } from './lib/context/SearchAddressContext';
import { AutocompleteContextProvider } from './lib/context/AutocompleteContext';
import { withSimulationContext } from './lib/context/SimulationContext';

const CssBaseline = Loadable({
  loader: () => import('./components/CssBaseline/CssBaseline'),
  loading() {
    return null;
  },
});

const Header = Loadable({
  loader: () => import('./components/Header/Header'),
  loading() {
    return null;
  },
});
const Footer = Loadable({
  loader: () => import('./components/Footer/Footer'),
  loading() {
    return null;
  },
});

// const Home = Loadable({
//   loader: () => import('./pages/home/Home'),
//   loading() {
//     return null;
//   },
// });

const NotFound = Loadable({
  loader: () => import('./pages/not-found/NotFound'),
  loading() {
    return null;
  },
});

const Search = Loadable({
  loader: () => import('./pages/search/Search'),
  loading() {
    return null;
  },
});

const SimulationResults = Loadable({
  loader: () => import('./pages/simulation-results/ShowSimulationResults'),
  loading() {
    return null;
  },
});

const PrintInput = Loadable({
  loader: () => import('./pages/print/PrintInput'),
  loading() {
    return null;
  },
});

const PrintImage = Loadable({
  loader: () => import('./pages/print/PrintImage'),
  loading() {
    return null;
  },
});

const ResetPassword = Loadable({
  loader: () => import('./pages/reset-password/ResetPassword'),
  loading() {
    return null;
  },
});

const AboutData = Loadable({
  loader: () => import('./pages/about-data/AboutData'),
  loading() {
    return null;
  },
});

const Term = Loadable({
  loader: () => import('./pages/term/Term'),
  loading() {
    return null;
  },
});

// const Login = () => {
//   const { loginWithRedirect } = useAuth0();

//   useEffect(() => {
//     loginWithRedirect();
//   }, [loginWithRedirect]);

//   return null;
// };

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export const helmetContext = {} as FilledContext;

const App: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [preloaded, setPreloaded] = useState(false);
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (isOpen && !preloaded) {
      setPreloaded(true);
    }
  }, [isOpen, preloaded]);

  useEffect(() => {
    if (isLoading) {
      // isLoading が true の間は何もしない
      return;
    }
    if (isAuthenticated) {
      // 認証されていれば遷移するページを指定する
      navigate('/search', { replace: true });
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  // ユーザーが未認証である場合、ログインフローを実行する。
  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Loading...</div>;
  }

  return (
    <>
      <CssBaseline />
      <PageWrap>
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <Main>
          <HelmetProvider context={helmetContext}>
            <NotificationContextProvider>
              <SearchAddressContextProvider>
                <AutocompleteContextProvider>
                  {/*<Suspense fallback={<div>Loading...</div>}>*/}
                  <Routes>
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/about" element={<AboutData />} />
                    <Route path="/term" element={<Term />} />
                    <Route path="*" element={<NotFound />} />
                    {/* プライベートなページは以下のように記述 */}
                    <Route
                      path="/search"
                      element={(
                        <Search />
                      )}
                    />
                    <Route
                      path="/simulation-results"
                      element={(
                        <SimulationResults />
                      )}
                    />
                    <Route
                      path="/print/input"
                      element={(
                        <PrintInput />
                      )}
                    />
                    <Route
                      path="/print/image"
                      element={(
                        <PrintImage />
                      )}
                    />
                    <Route
                      path="/resetpassword"
                      element={(
                        <ResetPassword />
                      )}
                    />
                  </Routes>
                  <Notification />
                </AutocompleteContextProvider>
              </SearchAddressContextProvider>
            </NotificationContextProvider>
          </HelmetProvider>
        </Main>
        <Footer />
      </PageWrap>
    </>
  );
};

export default withSimulationContext(App);
