export const QueryRegex = function QueryRegex(key: string) {
  return new RegExp(`[?&]${key}=([^&#]*)`);
};

export const FindParam = function FindParam(source: string, pattern: RegExp) {
  const check = source.match(pattern);
  if (!check || check.length !== 2) {
    return undefined;
  }
  if (check[1].length === 0) {
    return undefined;
  }
  return check[1];
};
