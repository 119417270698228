enum LogLevel {
  NONE = 0,
  ERROR = 1,
  WARN = 2,
  DEBUG = 3,
}

class Logger {
  static logLevel: LogLevel = LogLevel.NONE;

  static error(fileName?: string, functionName?: string, message?: string, ...args: any) {
    if (Logger.logLevel >= LogLevel.ERROR) {
      const logType = '[ERROR]';
      const newMessage = Logger.getMessage(logType, fileName, functionName, message);
      console.error(newMessage, ...args); // eslint-disable-line no-console
    }
  }

  static warn(fileName?: string, functionName?: string, message?: string, ...args: any) {
    if (Logger.logLevel >= LogLevel.WARN) {
      const logType = '[WARN]';
      const newMessage = Logger.getMessage(logType, fileName, functionName, message);
      console.warn(newMessage, ...args); // eslint-disable-line no-console
    }
  }

  static debug(fileName?: string, functionName?: string, message?: string, ...args: any) {
    if (Logger.logLevel >= LogLevel.DEBUG) {
      const logType = '[DEBUG]';
      const newMessage = Logger.getMessage(logType, fileName, functionName, message);
      console.log(newMessage, ...args); // eslint-disable-line no-console
    }
  }

  static groupStart(fileName?: string, functionName?: string, description?: string) {
    if (Logger.logLevel !== LogLevel.NONE) {
      const newMessage = Logger.getGroupMessage(fileName, functionName, description);
      console.group(newMessage); // eslint-disable-line no-console
    }
  }

  static groupEnd() {
    if (Logger.logLevel !== LogLevel.NONE) {
      console.groupEnd(); // eslint-disable-line no-console
    }
  }

  static getGroupMessage(fileName?: string, functionName?: string, description?: string) {
    const newMessageArray = [];
    if (fileName) {
      newMessageArray.push(fileName);
    }
    if (functionName) {
      newMessageArray.push(functionName);
    }
    if (description) {
      newMessageArray.push(description);
    }
    return newMessageArray.join(' - ');
  }

  static getMessage(logType: string, fileName?: string, functionName?: string, message?: string) {
    const newMessageArray = [];
    if (logType) {
      newMessageArray.push(logType);
    }
    if (fileName) {
      newMessageArray.push(fileName);
    }
    if (functionName) {
      newMessageArray.push(functionName);
    }
    if (message) {
      newMessageArray.push(message);
    }
    return newMessageArray.join(' - ');
  }
}

export default Logger;
