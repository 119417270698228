const locationData = {
  default: true,
  lat: 35.338874,
  lng: 139.48727,
  prefecture: '神奈川県',
  city: { city: '藤沢市' },
  postcode: '251',
  postcodeFull: '251-0052',
  completeAddress: '',
  // 以下はGeocode APIから緯度・経度を指定して取得した結果
  // https://maps.googleapis.com/maps/api/geocode/json?latlng=xxx,xxx&key=xxx
  plus_code: {
    compound_code: '8FQP+GWR 日本、神奈川県藤沢市',
    global_code: '8Q7X8FQP+GWR',
  },
  results: [
    {
      address_components: [
        {
          long_name: '７５',
          short_name: '７５',
          types: ['premise'],
        },
        {
          long_name: '藤沢',
          short_name: '藤沢',
          types: ['political', 'sublocality', 'sublocality_level_2'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
        {
          long_name: '251-0052',
          short_name: '251-0052',
          types: ['postal_code'],
        },
      ],
      formatted_address: '日本、〒251-0052 神奈川県藤沢市藤沢７５',
      geometry: {
        location: {
          lat: 35.3388978,
          lng: 139.4872513,
        },
        location_type: 'ROOFTOP',
        viewport: {
          northeast: {
            lat: 35.3402467802915,
            lng: 139.4886002802915,
          },
          southwest: {
            lat: 35.3375488197085,
            lng: 139.4859023197085,
          },
        },
      },
      place_id: 'ChIJIRhg8GxPGGART5EoWgGbXBc',
      plus_code: {
        compound_code: '8FQP+HW 日本、神奈川県藤沢市',
        global_code: '8Q7X8FQP+HW',
      },
      types: ['establishment', 'food', 'point_of_interest', 'store'],
    },
    {
      address_components: [
        {
          long_name: '７５',
          short_name: '７５',
          types: ['premise'],
        },
        {
          long_name: '藤沢',
          short_name: '藤沢',
          types: ['political', 'sublocality', 'sublocality_level_2'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
        {
          long_name: '251-0052',
          short_name: '251-0052',
          types: ['postal_code'],
        },
      ],
      formatted_address: '日本、〒251-0052 神奈川県藤沢市藤沢７５',
      geometry: {
        location: {
          lat: 35.3388978,
          lng: 139.4872513,
        },
        location_type: 'ROOFTOP',
        viewport: {
          northeast: {
            lat: 35.3402467802915,
            lng: 139.4886002802915,
          },
          southwest: {
            lat: 35.3375488197085,
            lng: 139.4859023197085,
          },
        },
      },
      place_id: 'ChIJzy9RTMtPGGARzNogJ4SkuiE',
      plus_code: {
        compound_code: '8FQP+HW 日本、神奈川県藤沢市',
        global_code: '8Q7X8FQP+HW',
      },
      types: ['street_address'],
    },
    {
      address_components: [
        {
          long_name: '藤沢駅',
          short_name: '藤沢駅',
          types: ['establishment', 'point_of_interest', 'transit_station'],
        },
        {
          long_name: '藤沢',
          short_name: '藤沢',
          types: ['political', 'sublocality', 'sublocality_level_2'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
        {
          long_name: '251-0052',
          short_name: '251-0052',
          types: ['postal_code'],
        },
      ],
      formatted_address: '日本、〒251-0052 神奈川県藤沢市藤沢 藤沢駅',
      geometry: {
        location: {
          lat: 35.3388984,
          lng: 139.4874695,
        },
        location_type: 'GEOMETRIC_CENTER',
        viewport: {
          northeast: {
            lat: 35.3402473802915,
            lng: 139.4888184802915,
          },
          southwest: {
            lat: 35.3375494197085,
            lng: 139.4861205197085,
          },
        },
      },
      place_id: 'ChIJC0kMNstPGGARmu_dZscfvjI',
      plus_code: {
        compound_code: '8FQP+HX 日本、神奈川県藤沢市',
        global_code: '8Q7X8FQP+HX',
      },
      types: ['establishment', 'point_of_interest', 'transit_station'],
    },
    {
      address_components: [
        {
          long_name: 'ルミネ藤沢店',
          short_name: 'ルミネ藤沢店',
          types: ['premise'],
        },
        {
          long_name: '７５',
          short_name: '７５',
          types: ['premise'],
        },
        {
          long_name: '藤沢',
          short_name: '藤沢',
          types: ['political', 'sublocality', 'sublocality_level_2'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
        {
          long_name: '251-0052',
          short_name: '251-0052',
          types: ['postal_code'],
        },
      ],
      formatted_address: '日本、〒251-0052 神奈川県藤沢市藤沢７５ ルミネ藤沢店',
      geometry: {
        bounds: {
          northeast: {
            lat: 35.3392531,
            lng: 139.4884368,
          },
          southwest: {
            lat: 35.3383375,
            lng: 139.4847483,
          },
        },
        location: {
          lat: 35.3390768,
          lng: 139.4868884,
        },
        location_type: 'ROOFTOP',
        viewport: {
          northeast: {
            lat: 35.3401442802915,
            lng: 139.4884368,
          },
          southwest: {
            lat: 35.3374463197085,
            lng: 139.4847483,
          },
        },
      },
      place_id: 'ChIJC7RGTMtPGGARF8tdUEkmUUs',
      types: ['premise'],
    },
    {
      address_components: [
        {
          long_name: '8FQP+GW',
          short_name: '8FQP+GW',
          types: ['plus_code'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
      ],
      formatted_address: '8FQP+GW 日本、神奈川県藤沢市',
      geometry: {
        bounds: {
          northeast: {
            lat: 35.338875,
            lng: 139.487375,
          },
          southwest: {
            lat: 35.33875,
            lng: 139.48725,
          },
        },
        location: {
          lat: 35.338874,
          lng: 139.48727,
        },
        location_type: 'GEOMETRIC_CENTER',
        viewport: {
          northeast: {
            lat: 35.3401614802915,
            lng: 139.4886614802915,
          },
          southwest: {
            lat: 35.3374635197085,
            lng: 139.4859635197085,
          },
        },
      },
      place_id: 'GhIJe7slOWCrQUARTUpBt5dvYUA',
      plus_code: {
        compound_code: '8FQP+GW 日本、神奈川県藤沢市',
        global_code: '8Q7X8FQP+GW',
      },
      types: ['plus_code'],
    },
    {
      address_components: [
        {
          long_name: '251-0052',
          short_name: '251-0052',
          types: ['postal_code'],
        },
        {
          long_name: '藤沢',
          short_name: '藤沢',
          types: ['political', 'sublocality', 'sublocality_level_2'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
      ],
      formatted_address: '日本 〒251-0052',
      geometry: {
        bounds: {
          northeast: {
            lat: 35.3680911,
            lng: 139.4908572,
          },
          southwest: {
            lat: 35.338414,
            lng: 139.464553,
          },
        },
        location: {
          lat: 35.349673,
          lng: 139.4809269,
        },
        location_type: 'APPROXIMATE',
        viewport: {
          northeast: {
            lat: 35.3680911,
            lng: 139.4908572,
          },
          southwest: {
            lat: 35.338414,
            lng: 139.464553,
          },
        },
      },
      place_id: 'ChIJ9-WCGDJOGGARkbY_8KVrt3U',
      types: ['postal_code'],
    },
    {
      address_components: [
        {
          long_name: '藤沢',
          short_name: '藤沢',
          types: ['political', 'sublocality', 'sublocality_level_2'],
        },
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
        {
          long_name: '251-0052',
          short_name: '251-0052',
          types: ['postal_code'],
        },
      ],
      formatted_address: '日本、〒251-0052 神奈川県藤沢市藤沢',
      geometry: {
        bounds: {
          northeast: {
            lat: 35.3680911,
            lng: 139.4908572,
          },
          southwest: {
            lat: 35.338414,
            lng: 139.464553,
          },
        },
        location: {
          lat: 35.349673,
          lng: 139.4809269,
        },
        location_type: 'APPROXIMATE',
        viewport: {
          northeast: {
            lat: 35.3680911,
            lng: 139.4908572,
          },
          southwest: {
            lat: 35.338414,
            lng: 139.464553,
          },
        },
      },
      place_id: 'ChIJQ2k0Q9dRGGARGagkZNqGgEA',
      types: ['political', 'sublocality', 'sublocality_level_2'],
    },
    {
      address_components: [
        {
          long_name: '藤沢市',
          short_name: '藤沢市',
          types: ['locality', 'political'],
        },
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
      ],
      formatted_address: '日本、神奈川県藤沢市',
      geometry: {
        bounds: {
          northeast: {
            lat: 35.4289555,
            lng: 139.5168019,
          },
          southwest: {
            lat: 35.2966708,
            lng: 139.3942993,
          },
        },
        location: {
          lat: 35.3391654,
          lng: 139.49014,
        },
        location_type: 'APPROXIMATE',
        viewport: {
          northeast: {
            lat: 35.4289555,
            lng: 139.5168019,
          },
          southwest: {
            lat: 35.2966708,
            lng: 139.3942993,
          },
        },
      },
      place_id: 'ChIJ9cQA541RGGARqJsCmpW-ezQ',
      types: ['locality', 'political'],
    },
    {
      address_components: [
        {
          long_name: '神奈川県',
          short_name: '神奈川県',
          types: ['administrative_area_level_1', 'political'],
        },
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
      ],
      formatted_address: '日本、神奈川県',
      geometry: {
        bounds: {
          northeast: {
            lat: 35.6728718,
            lng: 139.8357927,
          },
          southwest: {
            lat: 35.1286216,
            lng: 138.9157563,
          },
        },
        location: {
          lat: 35.4913535,
          lng: 139.284143,
        },
        location_type: 'APPROXIMATE',
        viewport: {
          northeast: {
            lat: 35.6728718,
            lng: 139.8357927,
          },
          southwest: {
            lat: 35.1286216,
            lng: 138.9157563,
          },
        },
      },
      place_id: 'ChIJq9Rn-8dUGGARViMnQtCc9xE',
      types: ['administrative_area_level_1', 'political'],
    },
    {
      address_components: [
        {
          long_name: '日本',
          short_name: 'JP',
          types: ['country', 'political'],
        },
      ],
      formatted_address: '日本',
      geometry: {
        bounds: {
          northeast: {
            lat: 45.6412626,
            lng: 154.0031455,
          },
          southwest: {
            lat: 20.3585295,
            lng: 122.8554688,
          },
        },
        location: {
          lat: 36.204824,
          lng: 138.252924,
        },
        location_type: 'APPROXIMATE',
        viewport: {
          northeast: {
            lat: 45.6412626,
            lng: 154.0031455,
          },
          southwest: {
            lat: 20.3585295,
            lng: 122.8554688,
          },
        },
      },
      place_id: 'ChIJLxl_1w9OZzQRRFJmfNR1QvU',
      types: ['country', 'political'],
    },
  ],
  status: 'OK',
};

export default locationData;
