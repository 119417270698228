// Default Footer Navigation for main site.
import React from 'react';
// import { colors } from '../../../lib/styles';
import FooterNavLink from './FooterNavLink';
import FooterNavLayout from './FooterNavLayout';
// import PrivacyModal from './PrivacyModal';

const FooterNav: React.FC = () => {
  return (
    <>
      <FooterNavLayout>
        <FooterNavLink
          dataAttr="info-footer-about-data"
          to="/about"
          text="Suncle for Businessのデータについて"
        />
        <FooterNavLink
          dataAttr="info-footer-term"
          to="/term"
          text="利用規約"
        />
      </FooterNavLayout>
      {/* <FooterNavLayout>
        <FooterNavLink
          trackingTag="ga-click-tracking-target"
          dataAttr="info-footer-about"
          to="/about"
          text="サンクルとは"
        />
        <FooterNavLink
          trackingTag="ga-click-tracking-target"
          dataAttr="info-footer-about"
          to="/about"
          text="Suncle for Businessのデータについて"
        />
        <FooterNavLink
          trackingTag="ga-click-tracking-target"
          dataAttr="info-footer-contact"
          to="/contact"
          text="お問い合わせ"
        />
      </FooterNavLayout>
      <FooterNavLayout>
        <FooterNavLink
          trackingTag="ga-click-tracking-target"
          dataAttr="info-footer-company"
          to="/company"
          text="企業情報"
        />
        <FooterNavLink
          trackingTag="ga-click-tracking-target"
          dataAttr="info-footer-company"
          to="/term"
          text="利用規約"
        />
        <PrivacyModal>プライバシーポリシー</PrivacyModal>
      </FooterNavLayout> */}
    </>
  );
};

export default FooterNav;
