import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import HeaderLogo from './HeaderLogo';
import MobileNavButton from './MobileNavButton';
import MobileNav from './MobileNav';
import { colors, mediaQueries as mq } from '../../lib/styles';
import useOnMapMobile from '../../lib/hooks/useOnMapMobile';
import useOnMobile from '../../lib/hooks/useOnMobile';

const useStyles = makeStyles(() => ({
  Header: {
    position: 'fixed',
    width: '100%',
    backgroundColor: `${colors.white}`,
    zIndex: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [mq.smOnly]: {
      height: 56,
    },
    [mq.mdUp]: {
      height: 72,
    },
  },
  Header__inner: {
    maxWidth: 1280,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mq.smOnly]: {
      margin: '0 16px',
    },
    [mq.mdUp]: {
      margin: '0 24px',
      position: 'relative',
    },
  },
  navSpace: {
    [mq.smOnly]: {
      height: 56,
    },
    [mq.mdUp]: {
      height: 90,
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagline: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    marginTop: '9px',
    fontSize: '10px',
    lineHeight: 1.4,
    fontWeight: 500,
    marginLeft: '13px',
    color: `${colors.textGray}`,
    [mq.smOnly]: {
      fontSize: '9px',
      marginTop: '2px',
      marginLeft: '10px',
    },
  },
  tagLarger: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    fontSize: '12px',
    lineHeight: 2,
    fontWeight: 500,
    marginLeft: '13px',
    color: `${colors.textGray}`,
  },
  SharpFlex: {
    display: 'flex',
    [mq.smOnly]: {
      justifyContent: 'center !important',
    },
  },
  SharpBorder: {
    borderBottom: 'solid 0.25px #e6e6e6',
    paddingLeft: '1.5%',
    paddingRight: '1.5%',
    [mq.smOnly]: {
      paddingLeft: '5%',
      paddingRight: '5%',
      paddingTop: 14,
      paddingBottom: 9,
    },
  },
  SharpWidth: {
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

type HeaderProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const Header: React.FC<HeaderProps> = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const isMapMobile = useOnMapMobile();
  const [path, setPath] = useState<string>();
  const isMobile = useOnMobile();
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    if (location && location.pathname) {
      switch (location.pathname) {
        // case '/odawara': {
        //   setPath('odawara');
        //   break;
        // }
        // case '/sim/tepcohometech': {
        //   setPath('enekari');
        //   break;
        // }
        // case '/sim/sharp': {
        //   setPath('sharp');
        //   break;
        // }
        case '/print/image': {
          setShowHeader(false);
          break;
        }
        default: {
          setShowHeader(true);
          setPath('suncle');
        }
      }
    }
  }, [location.pathname]);

  if (!showHeader) {
    return null;
  }
  return (
    <>
      <header className={clsx({
        [classes.Header]: true,
        [classes.SharpBorder]: path === 'sharp',
      })}
      >
        <div className={clsx({
          [classes.Header__inner]: true,
          [classes.SharpWidth]: path === 'sharp',
          [classes.SharpFlex]: path === 'sharp',
        })}
        >
          <div className={clsx({
            [classes.flexRow]: true,
            [classes.SharpFlex]: path === 'sharp',
          })}
          >
            <HeaderLogo path={path} />
            {!isMapMobile && path === 'suncle' && (
              <span className={classes.tagline}>ビジネスを、明るく照らす<br />持続可能な太陽光発電ソリューション。</span>
            )}
            {isMobile && path === 'suncle' && (
              <span className={classes.tagline}>ビジネスを、明るく照らす<br />持続可能な太陽光発電ソリューション。</span>
            )}
            {path === 'enekari' && (
              <span className={classes.tagLarger}>初期費用０で始める省エネライフ</span>
            )}
          </div>
          { path === 'suncle' && <MobileNavButton isOpen={isOpen} setIsOpen={setIsOpen} />}
          { path === 'suncle' && <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />}
        </div>
      </header>
      <div className={classes.navSpace} />
    </>
  );
};

export default React.memo(Header);
