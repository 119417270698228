// SimulationContextはシミュレーションに関する状態管理と共有のためのContext(複数のコンポーネントにまたがってデータを渡すための仕組み)を提供します。
// 各useEffectフックは、第二引数で状態の変更をリアクティブに監視し、変更があると状態の更新をトリガーします。
// 状態の更新はreducer関数（例: CoordinatesReducer）によって処理され、これにより関連するコンポーネントに再レンダリングが適宜行われます。
// 再レンダリングは各コンポーネント毎にuseContextで、読み込んでいる状態が変更された場合に発生します。
import React, { createContext, useReducer, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// データの管理と操作を行う関数とユーティリティ
import queryString from 'query-string';
import { QueryRegex, FindParam } from '../utils/queryString';
import defaultGeocode from '../data/defaultGeocode';
import {
  axiosInstance,
  useGetBillingCalculator,
  useGetSolarData,
  useGetSimulationResultByDocumentId,
} from '../hooks/useAxios';

// Simulation全体で使用されるデータ形を定義するカスタムタイプとインターフェース
import { CashFlow, GenerationConfig, SolarPanelConfig, SystemCost, LoanInfo, UpdateCashFlowParameterPayload } from '../../types/custom';
import { SimulationContextArgs, SimulationContextState, SimulationContextAction, GeocodeData } from './SimulationTypes';

// ビジネスロジック: 請求、システム生成、コストに関連するルールと計算を処理するユーティリティ関数
import { getPriceStructureFromBillingCalculatorStructure } from '../utils/billing';
import { mockSystemData, adjustEfficiency, defaultParameters as generationDefaultParameters } from '../utils/generation';
import { getUpfrontCost, loanCost, defaultParameters as systemDefaultParameters } from '../utils/systemCost';
import { calcSpecificCase, defaultParameters as cashFlowDefaultParameters } from '../utils/cashFlow';

// シミュレーション係数
import SIMULATION_PARAMETERS from './SimulationParameters';

// ログ出力
import Logger from '../utils/Logger';

const FILE_NAME = 'SimulationContext';

// 変数の初期値
const initialState: SimulationContextState = {
  shouldRefresh: true,
  shouldPanelsCountRefresh: false,
  isLoading: false,
  hasError: false,
  isInsideJapan: 'pending',
  geocodeData: defaultGeocode,
  // 推奨パネル枚数（BtoBでは不要なためコメントアウト）
  // recommendedPanelsCount: 0,
  panelsCounts: [],
  minPanelsCount: 0,
  maxPanelsCount: 0,
  // 発電予測量（最小）
  minYearlyEnergyAcKw: 0,
  monthlyBill: 10000,
  billCompareChartData: {
    max: 100000,
    finalRevenue: 0,
    fitRevenue: [0],
    electricSavings: [0],
    totalRevenue: [0],
    breakevenOrPayments: [0],
    initialPaymentBoxOffsetY: 0,
    breakevenBoxOffsetX: 0,
  },
  yearlyConsumption: 0, // 月の電気料金から算出した年間の電気使用量と推測される
  generationParameters: generationDefaultParameters,
  systemParameters: systemDefaultParameters,
  cashFlowParameters: cashFlowDefaultParameters,
  regressionModel: { slope: 1500, intercept: 0 },
  solarDataFileName: '',
};

// SETで、不要なレンダリングを避けつつ再レンダリングする
const CoordinatesReducer = (state: SimulationContextState, action: SimulationContextAction) => {
  // console.log('SimulationContext - CoordinatesReducer - Start');
  // console.log('action.type', action.type);
  // console.log('action.payload', action.payload);
  switch (action.type) {
    case 'SET': {
      if (action.payload) {
        const { coordinates, isInsideJapan, ...rest } = action.payload;
        if (coordinates && state.coordinates) {
          const { lat: currentLat, lng: currentLng } = state.coordinates;
          const { lat: newLat, lng: newLng } = coordinates;
          if (currentLat.toFixed(6) === newLat?.toFixed(6) && currentLng.toFixed(6) === newLng?.toFixed(6)) {
            // don't update the coordinates if the lat/lng are same within 6 places
            return {
              ...state,
              isLoading: false,
              hasError: false,
              ...rest,
            };
          }
        }
        return {
          ...state,
          isLoading: false,
          hasError: false,
          ...action.payload,
        };
      }
      return state;
    }
    case 'UPDATE_CASH_FLOW_PARAMETER': {
      if (action.payload) {
        const { parameterKey, parameterValue } = action.payload as UpdateCashFlowParameterPayload;
        return {
          ...state,
          cashFlowParameters: {
            ...state.cashFlowParameters,
            [parameterKey]: parameterValue,
          },
        };
      }
      return state;
    }
    case 'VERIFY_GEOCODE': {
      if (action.payload) {
        const { coordinates, isInsideJapan } = action.payload;
        return {
          ...state,
          isLoading: true,
          hasError: false,
          coordinates,
          isInsideJapan,
        };
      }
      return state;
    }
    case 'INIT_SIMULATION': {
      return {
        ...state,
        cashFlowParameters: {
          ...state.cashFlowParameters,
          elecPricePerKWh: SIMULATION_PARAMETERS.ELEC_PRICE_PER_KWH_DEFAULT,
          elecUsePerYear: SIMULATION_PARAMETERS.ELEC_USE_PER_YEAR_DEFAULT,
          panelCostPerKW: SIMULATION_PARAMETERS.PANEL_COST_PER_KW_DEFAULT,
          grantAmount: SIMULATION_PARAMETERS.GRANT_AMOUNT_DEFAULT,
        },
        solarDataFileName: undefined,
        simulationResultId: undefined,
        simulationResult: undefined,
      };
    }
    case 'LOADING': {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }
    default: {
      throw new Error();
    }
  }
};

const SimulationContext = createContext({} as SimulationContextArgs);

// xとyから傾き(slope)と切片(intercept)を求める関数
const linearRegression = (y: number[], x: number[]) => {
  let n = y.length;
  let sumX = 0; let sumY = 0; let sumXY = 0; let
    sumXX = 0;
  for (let i = 0; i < n; i++) {
    sumX += x[i];
    sumY += y[i];
    sumXY += (x[i] * y[i]);
    sumXX += (x[i] * x[i]);
  }
  let slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  let intercept = (sumY - slope * sumX) / n;
  return { slope, intercept };
};

export const SimulationContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [{ results: billingCalculator }, makeBillingCalculatorRequest] = useGetBillingCalculator();
  const [{ results: solarData }, makeSolarDataRequest] = useGetSolarData();
  const [{ results: simulationResult }, makeGetSimulationResultByDocumentIdRequest] = useGetSimulationResultByDocumentId();
  const [state, dispatch] = useReducer(CoordinatesReducer, initialState);
  const value = { state, dispatch };
  const [generationConfig, setGenerationConfig] = useState<GenerationConfig>();
  const [solarPanelConfigs, setSolarPanelConfigs] = useState<SolarPanelConfig[]>();
  const [systemCostCalcs, setSystemCostCalcs] = useState<SystemCost[]>();
  const [allCashFlows, setAllCashFlows] = useState<CashFlow[]>();
  // state.panelsCountとpanelsは使い分けわれている。
  // state.panelsCountが更新された場合、UE07でsetPanelsしている。
  // panelsはUE06を動かすためだけに使用しているよう。
  const [panels, setPanels] = useState<number | undefined>();
  SimulationContext.displayName = 'SimulationContext';

  // システムコストの配列から現在のパネル枚数に一致するシステムコストを取得して設定
  //
  // systemCostCalcsにはパネル枚数毎に以下の項目が設定されている。
  // ・loan・・・BtoBでは未使用なので、削除してもいいかもしれない。
  // ・maintenanceCost・・・常に0が設定されている？
  // ・rooftopAreaMeters2・・・屋根面積
  // ・upfrontCost・・・設置費用目安
  // ・yearlyEnergyAcKwh・・・年間発電量目安（ただし、画面の値と異なっている）
  const setSystemCost = async (panelsCount: number) => {
    const FUNCTION_NAME = 'setSystemCost';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]panelsCount', panelsCount);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]systemCostCalcs', systemCostCalcs);
    if (systemCostCalcs) {
      // ここで保存済シミュレーション結果のパネル枚数を設定すると
      // スライダーを動かしても設置費用目安が動かなくなるため、処理を削除した。
      // let targetPanelsCount = panelsCount;
      // if (state.simulationResult) {
      //   // 保存済のシミュレーション結果が存在する場合、そのパネル枚数に一致するシステムコストを取得する。
      //   console.log('state.simulationResult.data.systemCapacity', state.simulationResult.data.systemCapacity);
      //   // TODO: 保存時にパネル枚数も保存した方が良いか？
      //   targetPanelsCount = state.simulationResult.data.systemCapacity * 4;
      // }
      await systemCostCalcs.forEach((systemCost) => {
        if (systemCost.panelsCount === panelsCount) {
          // 対象のパネル枚数のシステムコストを取得して設定
          Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.selectedSystemCost', systemCost);
          // Tells everyone else the cost of being awesome.
          // console.log("setting selectedsystemcost", systemCost)
          dispatch({ type: 'SET', payload: { selectedSystemCost: systemCost } });
        }
      });
    }
    Logger.groupEnd();
  };

  // システム容量が最小の場合の発電予測量を設定
  const setMinYearlyEnergyAcKw = async (minPanelsCount: number) => {
    const FUNCTION_NAME = 'setMinYearlyEnergyAcKw';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]minPanelsCount', minPanelsCount);
    if (systemCostCalcs) {
      systemCostCalcs.forEach((systemCost) => {
        if (systemCost.panelsCount === minPanelsCount) {
          Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]minYearlyEnergyAcKw', systemCost.yearlyEnergyAcKwh);
          dispatch({ type: 'SET', payload: { minYearlyEnergyAcKw: systemCost.yearlyEnergyAcKwh } });
        }
      });
    }
    Logger.groupEnd();
  };

  // 緯度と経度が存在し、住所が存在しない場合、位置検証を開始
  // それ以外の場合は、位置情報をセット
  const initializeGeocode = (search: string, pathname: string, initialGeocode: GeocodeData, currentCoordinates: GeocodeData) => {
    const FUNCTION_NAME = 'initializeGeocode';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME, 'ジオコード初期化');
    const lat = FindParam(search, QueryRegex('lat'));
    const lng = FindParam(search, QueryRegex('lng'));
    const addr = FindParam(search, QueryRegex('addr'));
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]lat', lat);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]lng', lng);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]addr', addr);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]initialGeocode', initialGeocode);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[引数]currentCoordinates', currentCoordinates);
    if (lat && lng && !addr) {
      // 緯度・経度が存在する
      // かつ
      // 住所が存在しない場合
      if (!currentCoordinates || (Number(lat) !== currentCoordinates.lat && Number(lng) !== currentCoordinates.lng)) {
        // TODO: 「緯度が変更された、または経度が変更された」が正しい条件ではないか？
        // 現在の座標が存在しない、または、「緯度が変更された、かつ、経度が変更された」場合
        Logger.debug(FILE_NAME, FUNCTION_NAME, 'VERIFY_GEOCODE');
        Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.coordinates', Number(lat), Number(lng));
        dispatch({
          type: 'VERIFY_GEOCODE',
          payload: { coordinates: { lat: Number(lat), lng: Number(lng) }, isInsideJapan: 'pending' },
        });
      }
    } else {
      // TODO: ここで一旦、initialGeocode（埼玉県）を設定するのは、住所に対応する緯度・経度がまだ分からないから？
      // 緯度・経度が存在しない
      // または
      // 住所が存在する場合
      Logger.debug(FILE_NAME, FUNCTION_NAME, 'SET');
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.coordinates', { ...initialGeocode });
      dispatch({
        type: 'SET',
        payload: { coordinates: { ...initialGeocode }, isInsideJapan: 'pending' },
      });
    }
    Logger.groupEnd();
  };

  // UE01: 位置情報初期化、初回マウント時のみ実行
  //
  // [依存変数]
  // なし
  // [取得]
  // なし
  // [更新]
  // state.coordinates
  // state.isInsideJapan
  useEffect(() => {
    const FUNCTION_NAME = 'UE01';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME, '位置情報初期化');
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'location.search', location.search);
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'location.pathname', location.pathname);
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'defaultGeocode', defaultGeocode);
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'state.coordinates', state.coordinates);
    initializeGeocode(location.search, location.pathname, defaultGeocode, state.coordinates);
    Logger.groupEnd();
  }, []);

  // UE02: PATHが変わったら位置情報更新
  //
  // [依存変数]
  // location.pathname
  // [取得]
  // なし
  // [更新]
  // state.coordinates
  // state.isInsideJapan
  useEffect(() => {
    const FUNCTION_NAME = 'UE02';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME, '位置情報更新');
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'location.search', location.search);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]location.pathname', location.pathname);
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'defaultGeocode', defaultGeocode);
    Logger.debug(FILE_NAME, FUNCTION_NAME, 'state.coordinates', state.coordinates);
    if (location.pathname.includes('search')) {
      // console.log('URLにsearchを含んでいる場合、ジオコードを初期化する。');
      initializeGeocode(location.search, location.pathname, defaultGeocode, state.coordinates);
    }
    Logger.groupEnd();
  }, [location.pathname]);

  // UE03: 日本かどうか検証
  //
  // [依存変数]
  // state.isInsideJapan
  // state.coordinates
  // state.geocodeData
  // [取得]
  // state.geocodeData
  // solarData
  // billingCalculator
  // [更新]
  // state.isInsideJapan
  // state.geocodeData
  useEffect(() => {
    const FUNCTION_NAME = 'UE03';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME, '日本かどうか検証');
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.isInsideJapan', state.isInsideJapan);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.coordinates', state.coordinates);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.geocodeData', state.geocodeData);
    // 日本かどうか検証
    // TODO: show error message when not inside Japan
    const verifyIsInsideJapan = async () => {
      const FUNCTION_NAME_VIIJ = 'verifyIsInsideJapan';
      Logger.groupStart(FILE_NAME, FUNCTION_NAME_VIIJ);
      if (state.coordinates && state.isInsideJapan === 'pending') {
        // 緯度・経度が設定されている
        // かつ
        // 日本かどうかがpendingの場合
        dispatch({ type: 'LOADING' });
        try {
          Logger.debug(FILE_NAME, FUNCTION_NAME_VIIJ, 'ジオコードデータ取得開始（非同期）');
          const response = await axiosInstance.get(`/geocode?lat=${state.coordinates.lat.toFixed(6)}&lng=${state.coordinates.lng.toFixed(6)}`);
          if (response.status === 200 && response.data.geocodeData) {
            const { isInsideJapan, geocodeData } = response.data;
            Logger.debug(FILE_NAME, FUNCTION_NAME_VIIJ, 'ジオコードデータ取得完了');
            Logger.debug(FILE_NAME, FUNCTION_NAME_VIIJ, '[更新]isInsideJapan', isInsideJapan);
            Logger.debug(FILE_NAME, FUNCTION_NAME_VIIJ, '[更新]geocodeData', geocodeData);
            // console.log('SimulationContextのジオコードデータを更新する。');
            dispatch({ type: 'SET', payload: { isInsideJapan, geocodeData } });
            // 緯度・経度が更新された場合、緯度・経度でソーラーデータを取得
            Logger.debug(FILE_NAME, FUNCTION_NAME_VIIJ, 'ソーラーデータ取得開始（非同期）');
            makeSolarDataRequest({ lat: state.coordinates.lat, lng: state.coordinates.lng, solarDataFileName: '' });
            if (geocodeData.prefecture && geocodeData.city && geocodeData.postcode) {
              // 新しい都道府県、市区町村、郵便番号
              const { postcode: newPostcode } = geocodeData;
              // console.log('newPrefecture', newPrefecture);
              // console.log('newCity', newCity);
              // console.log('newPostcode', newPostcode);
              // 現在の都道府県、市区町村、郵便番号
              const { postcode: currentPostcode } = state.geocodeData || {};
              // console.log('currentPrefecture', currentPrefecture);
              // console.log('currentCity', currentCity);
              // console.log('currentPostcode', currentPostcode);
              // cityFullNameは未使用になっていたため、コメントアウトした。
              // if ((newPrefecture !== currentPrefecture || JSON.stringify(newCity) !== JSON.stringify(currentCity)) || !systemCostCalcs) {
              //   const cityFullName = newCity.aal2 ? `${newCity.aal2} ${newCity.city}` : newCity.city;
              //   // console.log('cityFullName', cityFullName);
              // }
              if (!billingCalculator || newPostcode !== currentPostcode) {
                Logger.debug(FILE_NAME, FUNCTION_NAME_VIIJ, 'billingCalculator取得開始（非同期）');
                makeBillingCalculatorRequest(newPostcode);
              }
            }
          } else {
            Logger.error(FILE_NAME, FUNCTION_NAME_VIIJ, 'ジオコードデータ取得エラー');
            dispatch({ type: 'ERROR' });
          }
        } catch (error) {
          Logger.error(FILE_NAME, FUNCTION_NAME_VIIJ, '日本かどうか検証エラー');
          dispatch({ type: 'ERROR' });
        }
      }
      Logger.groupEnd();
    };
    // simはBtoCのシャープのURLで、BtoBでは不要なので、コメントアウトした。
    // if (state.coordinates && (location.pathname.includes('search') || location.pathname.includes('sim'))) {
    if (state.coordinates && (location.pathname.includes('search'))) {
      // 緯度・経度が設定されている
      // かつ
      // シミュレーション画面の場合
      const newQs = queryString.stringify({
        ...queryString.parse(location.search),
        lat: state.coordinates.lat,
        lng: state.coordinates.lng,
        addr: undefined,
      });
      Logger.debug(FILE_NAME, FUNCTION_NAME, `${location.pathname}?${newQs}に遷移させる。`);
      // 「replace: true」の場合、ブラウザの「戻る」で戻れなくする。
      navigate(`${location.pathname}?${newQs}`, { replace: true });
    }
    // 日本かどうかを検証
    verifyIsInsideJapan();
    Logger.groupEnd();
  }, [state.isInsideJapan, state.coordinates, state.geocodeData]);

  // UE04: solarDataが更新されるかパラメータが変わったらadjustEfficiencyで各種値の再計算しsolarPanelConfigsに持たせる
  //
  // [依存変数]
  // solarData
  // state.generationParameters
  // [取得]
  // なし
  // [更新]
  // state.solarDataFileName
  // state.shouldPanelsCountRefresh
  // solarPanelConfigs
  // generationConfig
  useEffect(() => {
    const FUNCTION_NAME = 'UE04';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]solarData', solarData);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.generationParameters', state.generationParameters);
    if (solarData) {
      // SimulationContextにソーラーデータのファイル名を設定
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]solarDataFileName', solarData.fileName);
      dispatch({
        type: 'SET',
        payload: {
          solarDataFileName: solarData.fileName,
          // ソーラーデータが更新された場合、システム容量（パネル枚数）を更新
          shouldPanelsCountRefresh: true,
        },
      });

      // SolarPanelConfig、GenerationConfigの設定

      // 参考
      // https://developers.google.com/maps/documentation/solar/reference/rest/v1/buildingInsights/findClosest?hl=ja

      // SolarPanelConfigは、屋根上の太陽光発電パネルの特定の配置を表します。
      //
      // 屋根に設置するパネル数毎に以下の情報を保持している配列
      // ・panelsCount: パネル数
      // ・yearlyEnergyDcKwh: 年間発電量目安（DC）
      // ・yearlyEnergyDcKwhAdjusted: 年間発電量目安（調整後DC）
      // ・yearlyEnergyAcKwhAdjusted: 年間発電量目安（調整後AC）
      // ・セグメント毎の情報
      // 　・segmentIndex: セグメントインデックス
      // 　・panelsCount: パネル数
      // 　・yearlyEnergyDcKwh: 年間発電量目安（DC）
      // 　・pitchDegrees: 理論上の地面に対する屋根セグメントの角度。0 は地面と平行、90 は地面に対して垂直です。
      // 　・azimuthDegrees: 屋根セグメントが指しているコンパスの方向。
      //                     0 = 北、90 = 東、180 = 南です。
      //                     「平らな」屋根セグメント（0 に非常に近い pitchDegrees）の場合、
      //                     方位角が明確に定義されていないため、一貫性を保つために任意の 0（北）と定義します。
      let newSolarPanelConfigs: SolarPanelConfig[];

      // GenerationConfigは以下の情報を保持している。
      // ・center: 中心の緯度、経度
      // ・isSimulation: Solar APIから取得できた場合はtrue。取得できなかった場合、falseとして、ファイルに定義された仮のソーラーデータを返す。
      // ・maxPanelsCount: 最大パネル数
      // ・placeId: ？
      // ・rooftopAreaMeters2: 屋根面積
      // ・sunshineHours: 日照時間？
      let newGenerationConfig: GenerationConfig;

      // solarDataの取得時にデータだけでなく、idも返すようにした。
      // そのため、solarData変数の内容は{ id, data }のようになっている。
      if (solarData.isDummy) { // not real data from the Google sunroof API
        // Solar APIからソーラーデータを取得できなかった場合
        newSolarPanelConfigs = mockSystemData(state.generationParameters);
        newGenerationConfig = {
          center: state.coordinates,
          isSimulation: true,
          placeId: '',
          sunshineHours: state.generationParameters.defaultSunshineHours,
          rooftopAreaMeters2: solarData.data.areaMeters2, // TODO: Find a way to accurately determine roof size
          maxPanelsCount: newSolarPanelConfigs[newSolarPanelConfigs.length - 1].panelsCount,
        };
      } else {
        // Solar APIからソーラーデータを取得できた場合
        newSolarPanelConfigs = [...solarData.data.solarPotential.solarPanelConfigs].map(
          (config) => {
            const adjustedConf = adjustEfficiency(config, state.generationParameters);
            return adjustedConf;
          },
        );
        newGenerationConfig = {
          center: state.coordinates,
          isSimulation: false,
          placeId: solarData.data.name.split('/')[1],
          sunshineHours: solarData.data.solarPotential.maxSunshineHoursPerYear,
          rooftopAreaMeters2: solarData.data.solarPotential.wholeRoofStats.areaMeters2,
          maxPanelsCount: solarData.data.solarPotential.maxArrayPanelsCount,
        };
      }
      // 年間発電量はパネル枚数に概ね比例しているため、近似直線の傾きを切片を求める
      const x = newSolarPanelConfigs.map((config) => config.yearlyEnergyAcKwhAdjusted);
      const y = newSolarPanelConfigs.map((config) => config.panelsCount);
      state.regressionModel = linearRegression(y, x);

      Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]solarPanelConfigs', solarPanelConfigs);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]generationConfig', generationConfig);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.regressionModel', state.regressionModel);

      // solarPanelConfigsを更新
      setSolarPanelConfigs(newSolarPanelConfigs);
      // generationConfigを更新
      setGenerationConfig(newGenerationConfig);

      // ソーラーデータに含まれるパネル枚数の配列を取得
      const panelsCounts = newSolarPanelConfigs.map((solarPanelConfig) => solarPanelConfig.panelsCount);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]panelsCounts', panelsCounts);
      dispatch({ type: 'SET', payload: { panelsCounts: panelsCounts } });
    }
    Logger.groupEnd();
  }, [solarData, state.generationParameters]);

  // UE05: solarPanelConfigs等が変わったらパネルコストを計算
  //
  // [依存変数]
  // solarPanelConfigs
  // state.generationParameters.averageGenerationPerPanel：パネル1枚あたりの発電量
  // state.systemParameters.averageCostPerPanel：パネル1枚あたりの設置費用
  // [取得]
  // なし
  // [更新]
  // selectedCashFlow: undefined
  // selectedSystemCost: undefined
  // state.shouldRefresh: true
  // systemCostCalcs
  useEffect(() => {
    const FUNCTION_NAME = 'UE05';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]solarPanelConfigs', solarPanelConfigs);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.generationParameters.averageGenerationPerPanel', state.generationParameters.averageGenerationPerPanel);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.systemParameters.averageCostPerPanel', state.systemParameters.averageCostPerPanel);
    if (solarPanelConfigs) {
      // ソーラーデータからシステムコストの配列を作成
      let newSystemCostCalcs = solarPanelConfigs.map((panelConfig) => {
        // パネル枚数
        const newPanelCount = panelConfig.panelsCount;
        // 設置費用目安
        let upfrontCost = 0;
        // TODO: ローンの変数は不要であれば削除してもいい。
        let loanDetails: LoanInfo;
        // 設置費用目安を設定
        upfrontCost = getUpfrontCost(newPanelCount, state.systemParameters);
        // TODO: ローンの変数は不要であれば削除してもいい。
        loanDetails = loanCost(upfrontCost, state.systemParameters);
        return {
          panelsCount: newPanelCount,
          yearlyEnergyAcKwh: panelConfig.yearlyEnergyAcKwhAdjusted,
          upfrontCost,
          maintenanceCost: 0,
          loan: loanDetails,
          rooftopAreaMeters2: generationConfig?.rooftopAreaMeters2 || 0,
        };
      });
      dispatch({ type: 'SET', payload: { selectedCashFlow: undefined, selectedSystemCost: undefined, shouldRefresh: true } });
      // systemCostCalcsを更新
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]systemCostCalcs', newSystemCostCalcs);
      setSystemCostCalcs(newSystemCostCalcs);
    }
    Logger.groupEnd();
  }, [solarPanelConfigs, state.generationParameters.averageGenerationPerPanel, state.systemParameters.averageCostPerPanel]);

  // UE06: systemCostCalcsが変更されたときなどの副作用
  //
  // [依存変数]
  // systemCostCalcs
  // [取得]
  // なし
  // [更新]
  // state.minPanelsCount
  // state.maxPanelsCount
  useEffect(() => {
    const FUNCTION_NAME = 'UE06';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]systemCostCalcs', systemCostCalcs);
    // if (systemCostCalcs && billingCalculator) {
    if (systemCostCalcs) {
      // 最小パネル枚数を設定
      const newMinPanelsCount = Math.max(systemCostCalcs[0].panelsCount, SIMULATION_PARAMETERS.MIN_PANELS_COUNT);

      // 最小パネル枚数をもとにシステム容量が最小の場合の発電予測量を設定
      setMinYearlyEnergyAcKw(newMinPanelsCount);

      // 最大パネル枚数を設定
      // ソーラーデータから取得されたパネル枚数の最大値の制限は不要なため、コメントアウトした。
      // const newMaxPanelsCount = systemCostCalcs[systemCostCalcs.length - 1].panelsCount < 40000
      //   ? systemCostCalcs[systemCostCalcs.length - 1].panelsCount
      //   : 40000;
      const newMaxPanelsCount = systemCostCalcs[systemCostCalcs.length - 1].panelsCount;

      // state.minPanelsCountを更新
      // state.maxPanelsCountを更新
      const payload = {
        minPanelsCount: newMinPanelsCount,
        maxPanelsCount: newMaxPanelsCount,
        // billingCalculator,
      };

      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]minPanelsCount', newMinPanelsCount);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]maxPanelsCount', newMaxPanelsCount);

      dispatch({ type: 'SET', payload });
    }
    Logger.groupEnd();
  }, [systemCostCalcs]);

  // UE07: 月の電気料金が変更されたときに年間の電気使用量を計算する副作用
  //
  // [依存変数]
  // state.yearlyConsumption
  // state.billingCalculator
  // systemCostCalcs
  // state.cashFlowParameters
  // [取得]
  // なし
  // [更新]
  // allCashFlows
  useEffect(() => {
    const FUNCTION_NAME = 'UE07';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.yearlyConsumption', state.yearlyConsumption);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.billingCalculator', state.billingCalculator);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]systemCostCalcs', systemCostCalcs);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.cashFlowParameters', state.cashFlowParameters);
    if (systemCostCalcs && state.billingCalculator) {
      // typescript complains that state.billingCalculator could be undefined
      // if we use it directly in calcSpecificCase, even though the if should protect for that
      const billCalc = state.billingCalculator;
      const newCashFlows = systemCostCalcs.map((sysCostInfo) => {
        return calcSpecificCase(sysCostInfo, state.yearlyConsumption, billCalc, state.monthlyBill, state.cashFlowParameters);
      });
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]allCashFlows', newCashFlows);
      // allCashFlowsを更新
      setAllCashFlows(newCashFlows);
    }
    Logger.groupEnd();
  }, [state.yearlyConsumption, state.billingCalculator, systemCostCalcs, state.cashFlowParameters]);

  // UE08: キャッシュフロー配列が更新された場合、最大のキャッシュフローを取得し、そのパネル枚数をもとにシステム容量の値を決定する。
  //
  // [依存変数]
  // allCashFlows
  // [取得]
  // なし
  // [更新]
  // state.panelsCount
  // state.shouldRefresh: false
  useEffect(() => {
    const FUNCTION_NAME = 'UE08';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]allCashFlows', allCashFlows);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.shouldRefresh', state.shouldRefresh);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.shouldPanelsCountRefresh', state.shouldPanelsCountRefresh);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.panelsCount', state.panelsCount);
    // BtoBでは不要なためコメントアウトした。
    // 最適な設定を探す
    // async function findBestConfig(cashflows: CashFlow[], isSimulation: boolean | undefined) {
    //   console.log('SimulationContext - findBestConfig - Start');
    //   console.log('最適な設定を探す');
    //   console.log('cashflows', cashflows);
    //   console.log('isSimulation', isSimulation);
    //   let bestBuy: CashFlow = cashflows.length > 1 ? cashflows[0] : emptyCashFlow;

    //   const stoppingPoint = isSimulation ? 19 : state.cashFlowParameters.maxRecommendedPanelsCount; // 4.75 kW simulation recommendation
    //   const validCashFlows = cashflows.filter((c) => c.panelsCount <= stoppingPoint && c.panelsCount >= state.cashFlowParameters.minRecommendedPanelsCount);

    //   if (isSimulation) {
    //     return validCashFlows[validCashFlows.length - 1];
    //   }

    //   await validCashFlows.forEach((cashFlow) => {
    //     // TODO: if totalSavingsBuy does not increase significantly return;
    //     if (bestBuy.totalSavingsBuy < cashFlow.totalSavingsBuy) {
    //       bestBuy = cashFlow;
    //     }
    //   });
    //   console.log('bestBuy', bestBuy);
    //   return bestBuy;
    // }
    // 最大のキャッシュフローを取得する。
    function getMaxCashFlow(cashflows: CashFlow[]) {
      // console.log('SimulationContext - getMaxCashFlow - Start');
      // console.log('最大のキャッシュフローを取得する。');
      // console.log('cashflows', cashflows);
      const cashFlow = cashflows[cashflows.length - 1];
      // console.log('cashFlow', cashFlow);
      // console.log('SimulationContext - getMaxCashFlow -Start');
      return cashFlow;
    }
    // Expected behavior: On page load recommend a starting system size.

    // 指定したパネル枚数のキャッシュフローを取得する。
    function getCashFlow(cashflows: CashFlow[], panelsCount: number) {
      const cashFlow = cashflows.find((cf) => {
        return cf.panelsCount === panelsCount;
      });
      return cashFlow;
    }

    // generationConfigはBtoBでは不要なため、コメントアウトした。
    // if (allCashFlows && allCashFlows.length > 0 && generationConfig && state.shouldRefresh) {
    if (allCashFlows && allCashFlows.length > 0 && state.shouldRefresh) {
      // キャッシュフローが1件以上存在する
      // かつ
      // リフレッシュする場合

      let cashFlow;
      if (!state.shouldPanelsCountRefresh && state.panelsCount) {
        // パネル枚数をリフレッシュしない
        // かつ
        // パネル枚数が設定されている場合
        // 現在のパネル枚数のキャッシュフローを取得
        cashFlow = getCashFlow(allCashFlows, state.panelsCount);
      } else {
        // パネル枚数をリフレッシュする
        // または
        // パネル枚数が未設定の場合
        // 最大のキャッシュフローを取得
        cashFlow = getMaxCashFlow(allCashFlows);
      }

      Logger.debug(FILE_NAME, FUNCTION_NAME, 'cashFlow', cashFlow);

      let newPanelsCount;
      newPanelsCount = cashFlow?.panelsCount;
      if (state.simulationResult) {
        // console.log(`保存済シミュレーション結果が存在するため、そのパネル枚数[${state.simulationResult.data.panels_count}]を設定する。`);
        // 保存済シミュレーション結果が存在する場合、そのパネル枚数を設定する。
        newPanelsCount = state.simulationResult.data.panels_count;
      }

      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]panelsCount', newPanelsCount);

      dispatch({
        type: 'SET',
        payload: {
          panelsCount: newPanelsCount,
          shouldRefresh: false,
          shouldPanelsCountRefresh: false,
        },
      });

      // // キャッシュフロー配列の中から最大のキャッシュフローを取得し、そのパネル枚数を初期値とする。
      // // ただし、保存済シミュレーション結果が存在する場合、そのパネル枚数を初期値とする。

      // // BtoCでは、推奨キャッシュフローを取得していたが、BtoBでは不要なため
      // // 最大のキャッシュフローを取得するように変更した。
      // // findBestConfig(allCashFlows, generationConfig.isSimulation).then((r) => {
      //   getMaxCashFlow(allCashFlows).then((maxCashFlow) => {
      //     // console.log('SimulationContext - getMaxCashFlow - End');
      //     console.log('キャッシュフロー配列から最大のキャッシュフローを取得した。');
      //     console.log('maxCashFlow', maxCashFlow);
      //     console.log('maxCashFlow.panelsCount', maxCashFlow.panelsCount);

      //     // 最大のキャッシュフローのパネル枚数を新しいパネル枚数として設定する。
      //     let newPanelsCount = maxCashFlow.panelsCount;
      //     if (state.simulationResult) {
      //       console.log(`保存済シミュレーション結果が存在するため、そのパネル枚数[${state.simulationResult.data.panels_count}]を設定する。`);
      //       // 保存済シミュレーション結果が存在する場合、そのパネル枚数を設定する。
      //       newPanelsCount = state.simulationResult.data.panels_count;
      //     }
      //     console.log('newPanelsCount', newPanelsCount);
      //     console.log(`state.panelsCountを${state.panelsCount}から${newPanelsCount}に更新する。`);

      //     // 次のdispatchでpanelsCountを更新すると、その副作用（UE07）でsetPanelsは更新されるため、コメントアウトした。
      //     // setPanels(newPanelsCount);

      //     // findBestConfigを使用しなければrecommendedPanelsCountは不要なので、コメントアウトした。
      //     // dispatch({ type: 'SET', payload: { panelsCount: newPanelsCount, recommendedPanelsCount: r.panelsCount, shouldRefresh: false } });
      //     dispatch({ type: 'SET', payload: { panelsCount: newPanelsCount, shouldRefresh: false } });
      //   }).catch((e) => {
      //     // console.log("error get max cashflow")
      //   });
    }
    Logger.groupEnd();
  }, [allCashFlows]);

  // UE09: グローバル変数のパネル数の変化に応じてローカルなpanelsを更新
  //
  // [依存変数]
  // state.panelsCount
  // [取得]
  // なし
  // [更新]
  // panels
  useEffect(() => {
    const FUNCTION_NAME = 'UE09';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.panelsCount', state.panelsCount);
    // TODO: この時点で仮の10が設定されているのは無駄か？
    //       ソーラーデータが取得できていれば、その最大値を設定した方が良いか？
    // useStateは非同期で何が設定されたのか分かりづらいため、変数に格納した。
    const newPanelsCount = state.panelsCount || 10;
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]panels', newPanelsCount);
    // panelsを更新
    setPanels(newPanelsCount);
    Logger.groupEnd();
  }, [state.panelsCount]);

  // UE10: パネル数(panels)が変更されるたびに、該当するキャッシュフロー(selectedCashFlow)を再取得する副作用
  //
  // [依存変数]
  // panels
  // allCashFlows
  // [取得]
  // なし
  // [更新]
  // selectedCashFlow
  useEffect(() => {
    const FUNCTION_NAME = 'UE10';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]panels', panels);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]allCashFlows', allCashFlows);
    if (panels !== undefined && allCashFlows) {
      let cf = allCashFlows.filter((c) => c.panelsCount === panels);
      // console.log('cf', cf);
      if (cf.length === 1) {
        Logger.debug(FILE_NAME, FUNCTION_NAME, 'setSystemCostの開始（非同期）');
        setSystemCost(cf[0].panelsCount).then(() => {
          Logger.debug(FILE_NAME, FUNCTION_NAME, 'setSystemCostの完了');
          Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]selectedCashFlow', cf[0]);
          dispatch({ type: 'SET', payload: { selectedCashFlow: cf[0] } });
        });
      }
    }
    Logger.groupEnd();
  }, [panels, allCashFlows]);

  // UE11: billingCalculatorをもとに月額の電気料金や年間消費電力量を初期化する副作用
  //
  // [依存変数]
  // billingCalculator
  // state.cashFlowParameters.elecPricePerKWh
  // state.cashFlowParameters.elecUsePerYear
  // [取得]
  // なし
  // [更新]
  // state.priceStructure
  // state.monthlyBill: 10000
  // state.billingCalculator
  // state.yearlyConsumption
  useEffect(() => {
    const FUNCTION_NAME = 'UE11';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]billingCalculator', billingCalculator);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.cashFlowParameters.elecPricePerKWh', state.cashFlowParameters.elecPricePerKWh);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.cashFlowParameters.elecUsePerYear', state.cashFlowParameters.elecUsePerYear);
    if (billingCalculator) {
      let priceStructure;
      if (billingCalculator.type !== 'timebased') {
        priceStructure = getPriceStructureFromBillingCalculatorStructure(billingCalculator.structure, billingCalculator.baseCharge);
      }
      // const newMonthlyBill = monthlyKwhToYen(billingCalculator, state.yearlyConsumption / 12);
      const payload = {
        priceStructure,
        monthlyBill: 10000,
        billingCalculator,
        // yearlyConsumption: monthlyYenToKwh(billingCalculator, 10000) * 12,
        yearlyConsumption: state.cashFlowParameters.elecUsePerYear,
      };
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]priceStructure', priceStructure);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]billingCalculator', billingCalculator);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]yearlyConsumption', state.cashFlowParameters.elecUsePerYear);
      dispatch({ type: 'SET', payload });
    }
    Logger.groupEnd();
  }, [billingCalculator, state.cashFlowParameters.elecPricePerKWh, state.cashFlowParameters.elecUsePerYear]);

  // UE12: billingCalculatorが更新されたときに、新しい請求情報で価格構造と月/年間消費電量を再計算し、状態を更新する副作用
  //
  // [依存変数]
  // state.billingCalculator
  // state.cashFlowParameters.elecPricePerKWh
  // state.cashFlowParameters.elecUsePerYear
  // [取得]
  // なし
  // [更新]
  // state.priceStructure
  // state.monthlyBill: 10000
  // state.yearlyConsumption
  useEffect(() => {
    const FUNCTION_NAME = 'UE12';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.billingCalculator', state.billingCalculator);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.cashFlowParameters.elecPricePerKWh', state.cashFlowParameters.elecPricePerKWh);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.cashFlowParameters.elecUsePerYear', state.cashFlowParameters.elecUsePerYear);
    if (state.billingCalculator && (state.billingCalculator !== billingCalculator)) {
      let priceStructure;
      if (state.billingCalculator.type !== 'timebased') {
        priceStructure = getPriceStructureFromBillingCalculatorStructure(state.billingCalculator.structure, state.billingCalculator.baseCharge);
      }
      const payload = {
        priceStructure,
        monthlyBill: 10000,
        // yearlyConsumption: monthlyYenToKwh(state.billingCalculator, 10000) * 12,
        yearlyConsumption: state.cashFlowParameters.elecUsePerYear,
      };
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.priceStructure', priceStructure);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.yearlyConsumption', state.cashFlowParameters.elecUsePerYear);
      dispatch({ type: 'SET', payload });
    }
    Logger.groupEnd();
  }, [state.billingCalculator, state.cashFlowParameters.elecPricePerKWh, state.cashFlowParameters.elecUsePerYear]);

  // UE13: チャート更新用の副作用
  //
  // [依存変数]
  // state.selectedCashFlow
  // [取得]
  // なし
  // [更新]
  // state.billCompareChartData
  useEffect(() => {
    const FUNCTION_NAME = 'UE13';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.selectedCashFlow', state.selectedCashFlow);
    if (state.selectedCashFlow && state.selectedCashFlow.cashFlowSchedule && state.selectedSystemCost) {
      // Pick a max that will line up with revenue
      const newMax = Math.ceil((state.selectedCashFlow.totalFitRevenue + state.selectedCashFlow.totalElectricSavings) / 500000) * 50000;
      // const newTotalRevenue = [...state.billCompareChartData.totalRevenue];
      // const newBreakevenOrPayments = [...state.billCompareChartData.breakevenOrPayments];
      // const newFitRevenue = [...state.billCompareChartData.fitRevenue];
      // const newElectricSavings = [...state.billCompareChartData.electricSavings];
      const newTotalRevenue: number[] = [];
      let newBreakevenOrPayments: number[] = [];
      const newFitRevenue: number[] = [];
      const newElectricSavings: number[] = [];
      let paybackAmount: number | undefined;
      let paybackYear = state.selectedCashFlow.paybackPeriod;
      let newFinalRevenue = state.billCompareChartData.finalRevenue;
      state.selectedCashFlow.cashFlowSchedule.forEach((cfs) => {
        // newTotalRevenue.shift();
        newTotalRevenue.push(Math.round(cfs.totalFitAndElectricToDate));
        // if (state.selectedCashFlow?.paybackPeriod === cfs.year) {
        //   newBreakevenOrPayments.fill(cfs.totalFitAndElectricToDate);
        // }
        if (state.selectedCashFlow?.paybackPeriod === cfs.year) {
          if (state.selectedSystemCost && state.cashFlowParameters) {
            const { upfrontCost } = state.selectedSystemCost;
            const { grantAmount } = state.cashFlowParameters;
            paybackAmount = Math.max(upfrontCost - grantAmount, 0);
          }
        }
        // newFitRevenue.shift();
        newFitRevenue.push(Math.round(cfs.fitRevenue));
        // newElectricSavings.shift();
        newElectricSavings.push(Math.round(cfs.yearlyElectricSavings));
        newFinalRevenue = cfs.totalFitAndElectricToDate;
      });
      newBreakevenOrPayments = Array(newElectricSavings.length).fill(paybackAmount);
      // Create Loan Case
      // const YAS = state.selectedSystemCost.loan.yearlyAmortizationSchedule;
      // const monthlyLoanPayment = state.selectedSystemCost.loan.monthlyPayment;
      paybackYear = 15;
      const newBillCompareChartData = {
        max: newMax,
        finalRevenue: newFinalRevenue,
        fitRevenue: newFitRevenue,
        electricSavings: newElectricSavings,
        totalRevenue: newTotalRevenue,
        breakevenOrPayments: newBreakevenOrPayments,
        initialPaymentBoxOffsetY: 200 * (Math.abs(newBreakevenOrPayments[19] - newFinalRevenue) / newFinalRevenue) - 25,
        breakevenBoxOffsetX: 14 + ((paybackYear - 1) / 19) * 74,
      };
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.billCompareChartData', newBillCompareChartData);
      dispatch({ type: 'SET', payload: { billCompareChartData: newBillCompareChartData } });
    }
    Logger.groupEnd();
  }, [state.selectedCashFlow]);

  // UE14: 保存済シミュレーション結果IDが更新された場合
  //
  // [依存変数]
  // state.simulationResultId
  // [取得]
  // simulationResult
  // [更新]
  // なし
  useEffect(() => {
    const FUNCTION_NAME = 'UE14';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.simulationResultId', state.simulationResultId);
    if (state.simulationResultId) {
      // 保存済シミュレーション結果を取得
      Logger.debug(FILE_NAME, FUNCTION_NAME, '保存済シミュレーション結果取得開始（非同期）');
      makeGetSimulationResultByDocumentIdRequest(state.simulationResultId);
    }
    Logger.groupEnd();
  }, [state.simulationResultId]);

  // UE15: 保存済シミュレーション結果が更新された場合
  //
  // [依存変数]
  // simulationResult
  // [取得]
  // なし
  // [更新]
  // state.coordinates
  // state.panelsCount
  // state.solarDataFileName
  // state.simulationResult
  useEffect(() => {
    const FUNCTION_NAME = 'UE15';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]simulationResult', simulationResult);
    if (simulationResult) {
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.coordinates', simulationResult.data.latitude, simulationResult.data.longitude);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.solarDataFileName', simulationResult.data.solar_data_file_name);
      Logger.debug(FILE_NAME, FUNCTION_NAME, '[更新]state.simulationResult', simulationResult);
      dispatch({ type: 'SET',
        payload: {
          // SimulationContextの緯度・経度を更新
          coordinates: {
            lat: simulationResult.data.latitude,
            lng: simulationResult.data.longitude,
          },
          // パネル枚数を更新
          // ここでパネル枚数を更新しても、保存済シミュレーション結果の再読み込みにより、パネル枚数は上書きされてしまう。
          // panelsCount: simulationResult.data.panelsCount,
          // SimulationContextのソーラーデータIDを更新
          solarDataFileName: simulationResult.data.solar_data_file_name,
          // 保存済シミュレーション結果を更新
          simulationResult: simulationResult,
        },
      });
    }
    Logger.groupEnd();
  }, [simulationResult]);

  // UE16: ソーラーデータのファイル名が更新された場合
  //
  // [依存変数]
  // state.simulationResult?.data.solarDataFileName
  // [取得]
  // solarData
  // [更新]
  // state.coordinates
  // state.panelsCount
  // state.solarDataFileName
  // state.simulationResult
  useEffect(() => {
    const FUNCTION_NAME = 'UE16';
    Logger.groupStart(FILE_NAME, FUNCTION_NAME);
    Logger.debug(FILE_NAME, FUNCTION_NAME, '[依存]state.simulationResult.data.solar_data_file_name', state.simulationResult?.data.solar_data_file_name);
    if (state.simulationResult?.data.solar_data_file_name) {
      Logger.debug(FILE_NAME, FUNCTION_NAME, 'ソーラーデータ取得開始（非同期）');
      makeSolarDataRequest({ lat: 0, lng: 0, solarDataFileName: state.simulationResult.data.solar_data_file_name });
    }
    Logger.groupEnd();
  }, [state.simulationResult?.data.solar_data_file_name]);

  return (
    <SimulationContext.Provider value={value}>{children}</SimulationContext.Provider>
  );
};

export const withSimulationContext = (Component: React.FC) => {
  return () => <SimulationContextProvider><Component /></SimulationContextProvider>;
};

export default SimulationContext;
