import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import solarPanelIconImg from './images/solar-panel-icon.svg';
// import formIconImg from './images/form-icon.svg';
// import questionIconImg from './images/question-icon.svg';
// import speechIconImg from './images/speech-icon.svg';
// import solarPanelIconWhiteImg from './images/solar-panel-icon-white.svg';
// import formIconWhiteImg from './images/form-icon-white.svg';
// import questionIconWhiteImg from './images/question-icon-white.svg';
// import speechIconWhiteImg from './images/speech-icon-white.svg';
import { mediaQueries as mq, colors } from '../../lib/styles';
// import useOnMobile from '../../lib/hooks/useOnMobile';
import LoginButton from '../Login/LoginButton';

const useStyles = makeStyles(() => ({
  NavLink: {
    // フォントファミリー（最初から設定されていた値）
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    // フォントの太さ（最初から設定されていた値）
    fontWeight: 500,
    // 行の高さ（最初から設定されていた値）
    // PCの場合、1行なので、狭くてOK
    lineHeight: 1,
    // リンクの下線を非表示（最初から設定されていた値）
    textDecoration: 'none',
    // divで囲んだので不要
    // display: 'inline-block',
    // マージン（リンクの右にマージンを入れて、次の要素との間隔をあけた）
    marginRight: '20px',
    // フォントサイズ（最初から設定されていた値）
    fontSize: '.9em',
    [mq.smOnly]: {
      // スマホの場合
      // フォントカラー（最初から設定されていた値）
      // スマホの場合、背景がオレンジなので、白に設定
      color: colors.white,
      // フォントサイズ（最初から設定されていた値）
      // スマホの場合、大きくしないと小さくてタップし辛い
      fontSize: '1.2em',
      // 行の高さ
      // スマホの場合、縦並びなので、広めに設定
      lineHeight: 3,
      // マージン
      // スマホの場合、縦並びなので、PCの場合に必要な右マージンをクリアするために0に設定
      margin: 0,
      // '& + $NavLink': {
      //   marginTop: 40,
      // },
    },
    // [mq.layoutBPUp]: {
    //   fontSize: '1em',
    //   '& + $NavLink': {
    //     marginLeft: 35,
    //   },
    // },
    // [mq.mdUp]: {
    //   color: colors.textColor,
    //   '&:hover': {
    //     color: colors.orange,
    //     opacity: 0.8,
    //   },
    // },
  },
  // linkItemImg: {
  //   width: '26px',
  //   verticalAlign: 'middle',
  //   marginRight: '5px',
  //   [mq.layoutBPUp]: {
  //     width: '30px',
  //   },
  //   [mq.smOnly]: {
  //     width: '32px',
  //   },
  // },
  container: {
    display: 'flex',
    // 上下中央寄せ
    alignItems: 'center',
    [mq.smOnly]: {
      // スマホの場合
      // 縦並び
      flexFlow: 'column',
    },
  },
  // 保存済シミュレーション結果一覧
  navLinkSimulationResultsBox: {
    [mq.smOnly]: {
      // スマホの場合
      // 2番目に表示
      order: 2,
    },
  },
  // ログインボタン
  navLinkLoginButtonBox: {
    [mq.smOnly]: {
      // スマホの場合
      // 1番目に表示
      order: 1,
    },
  },
}));

type NavLinksProps = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const NavLinks: React.FC<NavLinksProps> = ({ onClick }) => {
  const classes = useStyles();
  // const isMobile = useOnMobile();
  // const [shouldReturnOffsite, setShouldReturnOffsite] = useState('');

  // const checkLandingPage = () => {
  //   if (window && window.location && window.location.pathname === '/sim/tepcohometech') {
  //     setShouldReturnOffsite('https://www.tepco-ht.co.jp/spv-cp2020/contact.html');
  //   }
  // };

  // useEffect(() => {
  //   checkLandingPage();
  //   window.addEventListener('popstate', checkLandingPage);
  //   return () => {
  //     window.removeEventListener('popstate', checkLandingPage);
  //   };
  // }, []);

  return (
    <>
      {/* { shouldReturnOffsite
        && (
        <Link
          to={shouldReturnOffsite}
          target="_blank"
          className={clsx(classes.NavLink, 'ga-click-tracking-target')}
          data-attr="return-to-sender"
          onClick={onClick}
        >
          サイトに戻る
        </Link>
        )}
      { !shouldReturnOffsite
          && ( */}
      <div className={classes.container}>
        <div className={classes.navLinkSimulationResultsBox}>
          <Link
            to="/simulation-results"
            className={clsx(classes.NavLink)}
          >
            保存済シミュレーション結果一覧
          </Link>
        </div>
        <div className={classes.navLinkLoginButtonBox}>
          <LoginButton />
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default React.memo(NavLinks);
