// シミュレーションパラメータ
const SIMULATION_PARAMETERS = {
  // 発電予測量の計算式
  // 発電予測量（DC調整前） = ソーラーデータから取得
  // 発電予測量（DC調整後） = 発電予測量（DC調整前） * SCALE_POTENTIAL * (JAPAN_EFFICIENCY / BASE_EFFICIENCY)
  // 発電予測量（AC調整後） = 発電予測量（DC調整後） * INVERTER_EFFICIENCY

  // スケールポテンシャル
  // ソーラーデータから取得した発電予測量を調整したい場合に使用する係数。
  SCALE_POTENTIAL: 100,
  // パネル発電効率（%）
  BASE_EFFICIENCY: 15.3,
  JAPAN_EFFICIENCY: 17.9,
  // パワコン効率（%）
  INVERTER_EFFICIENCY: 96.5,

  // パネルサイズ（m2）
  PANEL_SIZE_SIDE1: 1.879,
  PANEL_SIZE_SIDE2: 1.045,
  // パネル1枚あたりの発電量（kW）
  POWER_GENERATION_PER_PANEL: 0.40,

  // 出力低下率（%/年）
  DEGRADATION_RATE: 0.5,

  // 最小パネル枚数（4枚：システム容量1.6kW）
  // ソーラーデータでは、4枚からのデータが返ってくるが、BtoB向けとしては小さいため、最小システム容量をここで設定する。
  // 2024-05-16: ソーラーデータのパネル容量変更に伴う対応
  // 5枚以上の値を設定した場合、その値を最小値としてシミュレーションするが、
  // ソーラーデータの結果が4枚のみの場合、実際の屋根には4枚までしか設置できない。
  // そのため、ソーラーデータの最小値に合わせて4枚とする。
  MIN_PANELS_COUNT: 4,

  // CO2排出係数（t-CO/kWh）
  CARBON_DIOXIDE_T_PER_KWH: 0.000438,

  // cashFlow関連
  // インフレ率（%）
  INFLATION_RATE: 2.342,
  // 発電収支の開始年
  CASH_FLOW_START_YEAR: 1,
  // 発電収支の終了年
  CASH_FLOW_END_YEAR: 20,

  // 初期値
  // 電気料金単価の初期値（円/kWh）
  ELEC_PRICE_PER_KWH_DEFAULT: 18.44,
  // 電気使用量の初期値（kWh/年）
  ELEC_USE_PER_YEAR_DEFAULT: 0,
  // 設置費用の初期値（円/kW）
  PANEL_COST_PER_KW_DEFAULT: 222000,
  // 補助金の初期値（円）
  GRANT_AMOUNT_DEFAULT: 0,

  // 最小値
  // 電気使用量の最小値（kWh/年）
  ELEC_USE_PER_YEAR_MIN: 2000,
};

export default SIMULATION_PARAMETERS;
