import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  PageTitle: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  PageTitle__heading: {
    fontWeight: 600,
    position: 'relative',
    lineHeight: 1,
    transition: 'all .5s ease',
    margin: 0,
    opacity: 0,
    transform: 'scale(.9,.9)',
    [mq.smOnly]: {
      fontSize: 28,
      padding: '0 20px',
    },
    [mq.mdUp]: {
      fontSize: 46,
      padding: '3px 40px',
    },
    '&::before, &::after': {
      display: 'block',
      content: '""',
      transform: 'skewX(-13deg)',
      position: 'absolute',
      top: 0,
      [mq.smOnly]: {
        width: 5,
        height: 27,
      },
      [mq.mdUp]: {
        width: 10,
        height: 54,
      },
    },
    '&::before': {
      left: 0,
      backgroundColor: colors.orange,
    },
    '&::after': {
      right: 0,
      backgroundColor: colors.purple,
    },
  },
  PageTitle__lead: {
    fontWeight: 'bold',
    [mq.smOnly]: {
      fontSize: 14,
      margin: '8px 0 0',
    },
    [mq.mdUp]: {
      fontSize: 18,
      margin: '24px 0 0',
    },
  },
  isAnimated: {
    opacity: 1,
    transform: 'scale(1,1)',
    animationDelay: '.3s',
  },
}));

type PageTitleProps = {
  title: string;
  lead?: string;
  sub?: string;
};

const PageTitle: React.FC<PageTitleProps> = ({ children, title, lead, sub }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setInterval(() => {
      setIsAnimated(true);
    }, 300);
  }, []);

  return (
    <div className={classes.PageTitle}>
      <h1 data-testid="h1tag" className={clsx(classes.PageTitle__heading, isAnimated && classes.isAnimated)}>{title}</h1>
      {lead && <p className={classes.PageTitle__lead}>{lead}</p>}
      {sub && <p className={classes.PageTitle__lead}>{sub}</p>}
      {children}
    </div>
  );
};

PageTitle.defaultProps = {
  lead: undefined,
  sub: undefined,
};

export default PageTitle;
